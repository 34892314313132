/* .footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #333;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    color: white;
  }
  
  .footer-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    color: white;
  }
  
  .footer-icon {
    font-size: 24px;
    margin-bottom: 5px;
  }
   */
   


   .menu-btn {
  position: fixed;
  top: 10px;
  left: 10px;
  cursor: pointer;
  z-index: 1000; /* Ensures the icon is above other elements */
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #333;
  transition: transform 0.3s ease;
  overflow-y: auto;
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar.closed {
  transform: translateX(-100%);
}
