/* Chart.css */

.chart-container {
  width: 100%;
  height: 200px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  color: gray;
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  .chart-container {
    height: 300px; /* Define a suitable height */
    width: 100%;
  }
}

@media (max-width: 786px) {
  .chart-container {
    height: 300px; /* Define a suitable height */
    width: 100%;
    /* margin-left: 40px; */
  }
}

@media (max-width: 580px) {
  .chart-container {
    height: 200px; /* Define a suitable height */
    width: 100%;
  }
}

@media (max-width: 480px) {
  .chart-container {
    height: 200px; /* Define a suitable height */
    width: 100%;
  }
}

.chart-title {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 1.5rem;
  color: #333;
}

.chartGrid {
  stroke: rgb(228, 225, 225);
}
