@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}


.Customer-master-form {
  margin-top: 30px;
  max-width: 1380px;
  /* margin: 0 auto; */
  margin-left: 30px;
  padding: 40px;
  box-shadow: inset 11px 20px 20px 7px #2615151a;
  /* border-radius: 8px; */
  background-color: white;
  margin-right: 30px;
  animation: slideInFromLeft 0.9s ease-out;
  border-radius: 10px;
  /* Add this line */
}

.Customer-master-form label {
  display: block;
  margin-bottom: 10px;
  color: #333;
}

.Customer-master-form .form-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.Customer-master-form .form-field {
  flex: 1 1 22%;
  /* Adjust as needed to fit 4 items in a row */
  /* display: flex; */
  flex-direction: column;
  margin-bottom: 10px;
  font-size: 13px;
  /* Space between rows */
}

.Customer-master-form input,
.Customer-master-form textarea,
.Customer-master-form select {
  width: 100%;
  /* The input will take up the full width of its container */
  padding: 7px;
  margin-top: 5px;
  /* Adjust as needed */
  border: 1px solid #ccc;
  background-color: #100f0f08;
  border-radius: 4px;
  font-size: 13px;

}

.Customer-master-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #cccccc;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 9px;
  /* box-shadow: 20px 20px 60px #00000041, inset -20px -20px 60px #ffffff47; */
}

.Customer-master-form button:hover {
  background-color: #6ba870;
  margin-bottom: 15px;
  margin-top: 15px;
}

.Customer-master-form .required {
  color: red;
}

/* Responsive adjustments if necessary */
@media (max-width: 768px) {
  .Customer-master-form button {
    padding: 10px 10px;
  }

  .Customer-master-form .form-row {
    flex-direction: column;
  }

  .Customer-master-form .form-field {
    flex: 1 1 100%;
    font-size: 11px;
  }

  .Customer-master-form p {
    font-size: 13px;
  }

  .Customer-master-form .required {
    color: red;
  }

  .Customer-master-form input,
  .Customer-master-form textarea,
  .Customer-master-form select {
    font-size: 11px;

  }
}

.selected-container {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  padding: 15px;
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
}

.register-container {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  padding: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Overlay styles when zoomed in */
/* .overlay {
  position: fixed; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  align-items: center; 
  justify-content: center; 
  z-index: 10; 
} */

/* Hides the scrollbar to prevent scrolling when overlay is active */
body.overlay-active {
  overflow: hidden;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.ReactModal__Content {
  top: 45% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  max-width: 50% !important;
  width: auto !important;
  padding: 20px !important;
  border-radius: 8px !important;
  background-color: #fff !important;
  max-height: 80vh;
  /* Added max height */
  overflow-y: auto;
  /* Added overflow for the modal */
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
}

.modal-image-container {
  max-height: 60vh;
  /* Set the container height */
  overflow: auto;
  /* Enable scrolling */
}

.modal-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

@keyframes popUpFade {

  0%,
  100% {
    transform: scale(1);
    opacity: 0;
    color: red;
  }

  25% {
    transform: scale(1.5);
    opacity: 1;
    color: red;
  }

  75% {
    transform: scale(1.5);
    opacity: 1;
    color: red;
  }
}

.popUp {
  animation: popUpFade 2s ease-in-out infinite;
}




.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
}

.switchparent-container {
  display: flex;
}

.switch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  margin-left: auto;
  color: #100f0f08;
}

.switch-container p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.modal-content {
  width: 100%;
  height: 100%;
}


.form-container1 {
  display: flex;
  flex-wrap: wrap;
}

.form-container1>div {
  /* flex: 1 1 300px; */
  margin: 10px;
}

.form-field1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.inputField1 {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dropdown-menu1 {
  width: 100%;
}

.dropdown-item1 {
  display: block;
  width: 100%;
  padding: 8px 16px;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.green-dropdown .dropdown-menu1 {
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}

@media (max-width: 768px) {
  .form-container1 {
    flex-direction: column;
  }

  .form-container1>div {
    margin-left: 0;
    margin-right: 0;
    min-width: 100%;
    margin-top: 150px;
  }

  .header-container {
    margin-bottom: 20px;
  }

  .bigtitle {
    font-size: 17px;
  }

  .map-container1 {
    width: 100%;
    height: 300px;
    /* Adjust as needed */
  }

  .form-fields-container {
    margin-top: 50px;
  }
}

@media (min-width: 769px) {
  .map-container1 {
    flex: 2;
    height: 500px;
    /* Adjust as needed */
  }
}a

.downloadButtonIcon {
  cursor: pointer;
  margin-top: 20px;
  color: green
}

.tableCustomStyles .headRow {
  color: #ffff;
  background-color: rgb(169 187 169);
  font-weight: "bold";
  font-weight: 13px
};

.tableCustomStyles .pagination button {
  background: none;
  box-shadow: none;
};

.tableCustomStyles .striped {
  color: red
};

.tableCustomStyles .rows {
  background-color: #f2f2f2;

}