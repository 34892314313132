/* Base and Reset Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'sans-serif';
}

body, html {
  height: 100%;
  overflow-x: hidden;
}a

.highlight {
  background-color: yellow; /* Highlight color */
  transition: background-color 0.5s ease; /* Smooth transition */
}

/* Sidebar Styles */
.admin-page .sidebar {
  width: 100%; /* Extend the sidebar to full width */
  height: 10%; /* Set a fixed height */
  background: #ffffff;
  color: teal;
  font-size: 20px;
  position: fixed;
  top: 0;
  left: 0;
  /* overflow-x: auto;  */
  overflow-y: visible;
  display: flex; /* Use flexbox to align items horizontally */
  align-items: center; /* Center items vertically within the bar */
  z-index: 1000;
}

.admin-page .sidebar ul {
  list-style: none;
  display: flex; /* Display list items in a row */
  /* width: 100%; */
  padding:15;
  margin: 0;
  position: relative;
}
.blur {
  filter: blur(5px); /* Adjust the value for more or less blur */
  transition: filter 0.3s ease; /* Smooth transition effect */
}


.admin-page .sidebar li {
  position: relative;
  padding: 10px 20px; 
  cursor: pointer;
  transition: background 0.3s ease;
  white-space: nowrap;
  z-index: 1000;
  font-size: 13px;
}

  .admin-page .sidebar .submenu {
    display: none;  /* Hidden by default, will be shown dynamically */
    position: absolute;
    top: 100%;  /* Align right below the Reports menu item */
    left: 0;
    background: white;
    box-shadow: 0 4px 8px rgba(0,0,0,0.5);
    width: 160px;  
    z-index: 1050;
    /* margin-top: 9px; */
  }

.admin-page .sidebar li.active > .submenu,
.admin-page .sidebar li:hover > .submenu {
  display: block;   
}

.admin-page .sidebar .submenu li {
  color: teal; /* Submenu text color */
  /* padding: 10px; */
  cursor: pointer; /* Make it clear that submenu items are clickable */
  text-align: left;
}

.admin-page .sidebar .submenu li:hover {
  background: rgb(244, 236, 205); /* Submenu item hover effect */
}

.admin-page .content {
  margin-left: 160px; /* make room for the sidebar */
  padding: 20px;
  /* background: transparent; */
  /* min-height: 100vh; full view height */
  transition: margin-left 0.5s; /* animate the transition */
  width: calc(100% - 240px); /* remaining width */
  /* margin-right: 80px; */
  padding-bottom: 60px;
}

.admin-page .sidebar.collapsed + .content {
  margin-left: 60px; /* Sidebar collapsed width */
  width: calc(100% - 60px); /* Adjust width based on the collapsed sidebar */
}

.admin-page .content .centered {
  max-width: 960px; /* maximum width for the centered content */
  margin: 0 auto; /* center in the available space */
  display: flex;
  flex-direction: column;
  align-items: center; /* center items vertically in the container */
  margin-top: 50px;
}

.admin-page footer {
  background: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  margin-top: 20px; /* Space between content and footer */
}

.admin-page .sidebar.collapsed + .content + footer {
  left: 60px; /* Position it correctly for collapsed sidebar */
  width: calc(100% - 60px); /* Adjust width accordingly */
}

@media screen and (max-width: 768px) {
  .admin-page .sidebar {
    width: 25px; /* Set the width of the sidebar to 250px, or adjust as needed */
    height: 100%; /* Full height to cover the entire screen vertically */
    position: fixed;
    top: 0;
    left: 0; /* Align to the left */
    display: flex; /* Using flexbox */
    flex-direction: column; /* Align children (ul, li) vertically */
    overflow-y: auto; /* Enable scrolling if content overflows vertically */
    z-index: 1000; /* Ensure sidebar stays on top of other content */
    background: white; /* Sidebar background color */
    color:teal; /* Text color for sidebar */
    transition: transform 0.3s ease; /* No transition effect is necessary */
    font-size: 15px;
  }
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
    color: black;
  }
  .menu-btn {
    display: none; /* Hidden by default */
    position: fixed;
    left: 10px;
    top: 10px;
    cursor: pointer;
    font-size: 24px;
    color: #333;
    z-index: 1001; /* Ensure it's visible above other content */
  }

  /* Show the menu button when sidebar is hidden */
  .admin-page .menu-btn.show {
    display: block;
  }

  .admin-page .sidebar ul {
    width: 100%; 
    flex-direction: column;
  }

  .admin-page .sidebar li {
    text-align: left; /* Align text to the left */
    width: 100%; /* Each li takes full width of the sidebar */
    padding: 0;
    margin-left: 5px;
    margin-top: 5px;
    padding-top:10px;
    padding-bottom: 10px;
  }

  .admin-page .sidebar .submenu {
    position: static; /* Submenus appear within the flow of the document */
    display: none; /* Hidden by default */
  }

  .admin-page .sidebar li.active > .submenu,
  .admin-page .sidebar li:hover > .submenu {
    display: block;   
    margin-top: 10px;
  }

  /* Content adjustments when sidebar is visible */
  .admin-page .content {
    margin-left: 250px; 
    width: calc(100% - 250px);
  }

  .admin-page footer {
    position: fixed;
    left: 250px; /* Align the footer with the content area */
    width: calc(100% - 250px); /* Footer width matches the content area */
    bottom: 0; /* Position at the bottom */
  }
}




.admin-page .content header {
  background: #4CAF50;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  margin-bottom: 30px;
}

/* Admin Header Styles */
.admin-page .content .admin-header {
  background: #4CAF50;
  color: #fff;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 30px;
}

/* Summary Boxes Styles */
.admin-page .content .summary-boxes {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.admin-page .content .summary-boxes .box {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 20px;
  text-align: center;
  flex-basis: calc(33.333% - 20px);
  transition: transform 0.3s ease;
}

.admin-page .content .summary-boxes .box:hover {
  transform: translateY(-5px);
}

.admin-page .content .detail-boxes .box {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 20px;
  margin-bottom: 20px;  
}

.admin-page footer {
  background: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  width: calc(100% - 240px);
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .admin-page .sidebar,
  .admin-page .sidebar.collapsed + .content,
  .admin-page .sidebar.collapsed + .content + footer,
  .admin-page footer {
    margin-left: 0;
    width: 40%;
  }
  .admin-page .content {
    margin-left: 0;
    width: 100%;
    padding-bottom: 60px;
  }
}
.admin-header {
  background-color: #4CAF50; /* Green background */
  color: white;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 30px;
}
.heading-box {
  font-size: 24px; /* Large font size for headings */
  color: #333; /* Dark grey color */
  margin: 20px 0; /* Spacing above and below the heading */
}

.dashboard-summary {
  display: flex;
  justify-content: space-around; /* Space out the summary items */
  flex-wrap: wrap; /* Allow items to wrap on small screens */
  gap: 20px; /* Space between items */
  margin-bottom: 30px; /* Spacing below the section */
}
.summary-item {
  background: #fff;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 20px;
  text-align: center; /* Center athe text */
  flex-basis: calc(33.333% - 20px); /* Take up a third of the container width, minus gap */
}

.dropdown-container {
  margin-top: 10px;
  position: absolute;
  top: 50px;
  right: 0;
  width: 200px;
  padding: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1000;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdown-container.show {
  opacity: 1;
  transform: translateY(0);
  width: 225px;

}

.icon {
  font-size: 15px;
  color:orange;
  margin-right: 3px;
}


.icon2 {
  font-size: 9px;
  color:rgb(111, 238, 160);
  margin-right: 3px;
}

/* .li-class {
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
} */
 .company-img{
  height: 50px;
  width: 80px;
  margin-right: 8px;
 }

@media (max-width: 1158px) {
  .icon {
    font-size: 16px;
  }

  .admin-page .sidebar li {
    font-size: 10px;
  }

  .company-img{
    height: 50px;
    width: 60px;
   }
}

@media (max-width: 930px) {
  .icon {
    font-size: 14px;
  }

  .admin-page .sidebar li {
    font-size: 9px;
  }
  .company-img{
    height: 40px;
    width: 50px;
   }
}
.first-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px;*/
  margin-right: 30px; 
  margin-top: 50px;
  position: relative;
}

@media (max-width: 495px) {
  .first-container {
    /* justify-content: flex-start; */
    margin-left: 15px;
    margin-top: 0px;
  }
}