.searchbar {
  position: relative;
  /* height: 50px; */
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 25px;
  /* padding: 5px; */
  margin-top: 20px;
  height: 30px;
}

.search_input {
  border: 0;
  outline: 0;
  background: none;
  width: 85%;
  padding: 0;
  margin-left: 10px;
  color: #333;
}

.search_icon {
  color: #333;
  font-size: 1.5em;
  position: absolute;
  right: 15px;
  top: 7px;
}

  
.start-container {
  overflow: auto;
  margin: 20px 10px 20px 5px;
  height: 150px;

  /* Hide scrollbar on Internet Explorer and Edge */
  -ms-overflow-style: none;

  /* Hide scrollbar on Firefox */
  scrollbar-width: none;
}

/* Hide scrollbar on WebKit browsers (Chrome, Safari) */
.start-container::-webkit-scrollbar {
  display: none;
}


.imageContainer {
  display: flex;
  height: 100px;
  width: 100px;
  
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  text-align: center;
}

.image {
  width: 100px;
  margin: 0 5px;
  border: 1px solid green;
  padding: 3px;
  border-radius: 5px 5px 0px 0px;
}

.imageWrapper .description {
  background: radial-gradient(#fff8f8, #25601b57);
  /* width: 91%; */
  border: 1px solid;
  border-top: none;
  padding: 5px;
  border-radius: 0px 0px 5px 5px;
  text-align: center;
}

.imageWrapper .description p {
  font-size: 12px;
  color: rgb(53, 105, 32);
  margin: 0;
}

.image:nth-child(1) {
  background: radial-gradient(rgb(34, 0, 255), rgba(0, 0, 0, 0.14));
}

.image:nth-child(2) {
  background: radial-gradient(#000000, #00000024);
}

.image:nth-child(3) {
  background: radial-gradient(#6cd961, #00000024);
}

.image:nth-child(4) {
  background: radial-gradient(rgb(254, 0, 0), rgba(0, 0, 0, 0.14));
}


.leaflet-bar.geosearch-bar {
  background-color: rgba(255, 255, 255, 0.9);
  /* Background color */
  border-radius: 5px;
  /* Round corners */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* Shadow for the search box */
}

.geosearch-input {
  font-size: 14px;
  /* Adjust font size of the search input */
  padding: 5px 10px;
  /* Adjust padding for the input field */
}

.geosearch-button {
  background-color: #007bff;
  /* Set button background color */
  color: white;
  /* Set button text color */
  border-radius: 5px;
}

.imageWrapper .top-scrolling {
  background: #c6c6c6;
  /* border: 1px solid; */
  /* border-top: none; */
  padding: 6px 15px;
  border-radius: 5px 5px 5px 5px;
  text-align: center;
  min-width: 90px;
  display: inline-block;
  white-space: nowrap;
  
}

.imageWrapper .top-scrolling p {
  font-size: 15px;
  color: black;
  margin: 0;
}

.imageWrapper.selected .top-scrolling{
  background-color: #494949;
}

.imageWrapper.selected .top-scrolling p {
  color: white; /* Change to your preferred color */
}

.imageWrapper.selected {
  background-color: rgba(142, 162, 244, 0.1); /* Optional background change */
}

.allservices-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 20px;
}

.allservices-info {
  padding: 20px;
}

.allservices-name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.allservices-details {
  margin-bottom: 15px;
}

.allservices-price {
  font-size: 18px;
  font-weight: bold;
}