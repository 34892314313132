.payment {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2; /* Light background color */
    padding: 20px;
  }
  
  .book_container {
    width: 400px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
    overflow: hidden;
  }
  
  .book_img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  
  .book_details {
    padding: 20px;
    text-align: center;
  }
  
  .book_name {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .book_author {
    font-size: 18px;
    font-weight: normal;
    margin: 10px 0;
    color: #555;
  }
  
  .book_description {
    font-size: 16px;
    margin: 10px 0;
    color: #777;
  }
  
  .book_price {
    margin: 20px 0;
    color: #21a700;
    font-size: 22px;
    font-weight: bold;
  }
  
  .buy_btn {
    width: 100%;
    height: 50px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 0 0 10px 10px;
    background-color: #0a50b7;
    color: #fff;
    transition: background-color 0.3s ease;
  }
  
  .buy_btn:hover {
    background-color: #073c86;
  }
  
  @media (max-width: 768px) {
    .book_container {
      width: 90%;
    }
  
    .book_details {
      padding: 15px;
    }
  
    .book_name {
      font-size: 20px;
    }
  
    .book_author, .book_description, .book_price {
      font-size: 14px;
    }
  
    .buy_btn {
      font-size: 12px;
    }
  }
  