
  /* Gradient Background */
  .gradient-background {
    background:linear-gradient(rgb(70 140 75), rgb(11 15 9), rgb(255 255 255));
    padding-top: 60px; 
    /* width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box; */
  }
  
  /* Menu Container */
  .menu-container-profile {
    width: 85%;
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between menu items */
    margin-bottom: 100px;
    margin-top: 50px;
  }
  
  /* Individual Menu Items */
  .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border: 1px solid black;
    border-radius: 30px;
    background-color: #d5d5d5;
    color: #1c5e1c;
    font-weight: bold;
    font-size: 18px;
    padding: 0 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for better visuals */
  }
  
  /* Centered Text */
  .menu-text {
    flex: 1;
    text-align: center;
  }
  
  /* Arrow Icon */
  .icon {
    color: darkgreen;
  }
  
  /* Bottom Navigation */
  .bottom-bar {
    margin-top: 90px;
    width: 100%;
  }
  