.vendor-response-container {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.vendor-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
@media (min-width:250px) and (max-width:319px) {
    .vendor-table {
        max-width: 245px
    }
}
@media (min-width:320px) and (max-width:339px) {
    .vendor-table {
        max-width: 290px
    }
}
@media (min-width:340px) and (max-width:375px) {
    .vendor-table {
        max-width: 350px
    }
}
@media (min-width:375px) and (max-width:429px) {
    .vendor-table {
        max-width: 380px
    }
}
@media (min-width:430px) and (max-width:575px) {
    .vendor-table {
        max-width: 420px
    }
}
@media (min-width:576px) and (max-width:768px) {
    .vendor-table {
        max-width: 540px
    }
}
@media (min-width:768px) and (max-width:992px) {
    .vendor-table {
        max-width: 720px
    }
}
@media (min-width:992px) and (max-width:1100px) {
    .vendor-table {
        max-width: 960px
    }
}
@media (min-width:1101px) and (max-width:1200px) {
    .vendor-table {
        max-width: 1150px
    }
}
@media (min-width:1201px) and (max-width:1300px) {
    .vendor-table {
        max-width: 1260px
    }
}

.vendor-table th, .vendor-table td {
    padding: 12px 15px;
    text-align: left;
}

/* .vendor-table thead {
    background-color: #077ede;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
}

.vendor-table tbody tr:nth-child(even) {
    background-color: #f4f4f4;
}

.vendor-table tbody tr:hover {
    background-color: #eaeaea;
    cursor: pointer;
} */

.action-btn {
    padding: 6px 12px;
    background-color: #077ede;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
}

.action-btn:hover {
    background-color: #055bb5;
}

.no-data-message {
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 16px;
    color: #777;
    font-style: italic;
    text-align: center;
}
