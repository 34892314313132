.user-user-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #f4f4f4;
  }
  
  .user-form {
    background: #ffffff;
    padding: 50px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    min-width: 300px;
    /* height: 100vh; */
    margin-top: 10%;
    border-radius: 50px 50px 0px 0px;
  }


  input-profile {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
  
  input-profile:focus {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  }
  
  .user-form-group {
    display: flex;
    align-items: center;
    margin: 20px 0 40px 0;
  }
  
  .user-icon {
    margin-right: 30px;
    color: #555;
    font-size: 20px;
  }
  

  
  .user-submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s ease;
  }
  
  .user-submit-button:hover {
    background: #0056b3;
  }
  
  .user-submit-button .submit-icon {
    margin-right: 8px;
  }
  