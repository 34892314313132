table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

/* Apply border to the bottom of table rows instead of cells to avoid vertical lines */
tr, th, td {
  border-bottom: 1px solid #ddd;
  text-align: center;
}

th, td {
  text-align: left;
  padding: 8px;
  text-align: center;

}

th {
  font-weight: bold;
  font-size: large;
  color: black;
  text-align: center;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}
  
  .view-button {
    padding: 10px 20px;
    background-color: lightblue;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
}