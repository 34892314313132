.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}
.vertical-table {
    display: table;
    width: 100%;
    margin-bottom: 20px;
}

.table-row {
    display: table-row;
}

.table-cell {
    display: table-cell;
    padding: 8px;
    border-bottom: 1px solid #ddd;
    color: white;
}

.table-cell:first-child {
    font-weight: bold;
    background-color: #1d7a0f;
    color: black;
}
