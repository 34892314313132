.photo-upload-container {
    /* margin: 20px; */
}

.photo-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    margin-top: 20px;
}

.photo-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.photo-wrapper img {
    width: 100%;
    height: 150px;
    object-fit: cover; /* Ensure the images cover the area well */
}

.photo-wrapper button {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #ff0000cc;
    color: white;
    cursor: pointer;
}

.view-button {
    margin-top: 60px;
    padding: 6px 16px;
    background-color: lightblue;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
  }
