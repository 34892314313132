.vendor-response-form {
    margin-top:30px;
    max-width: 1380px;
    /* margin: 0 auto; */
    margin-left: 10px;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(38, 21, 21, 0.1);
    /* border-radius: 8px; */
    background-color: white;
    margin-right: 30px;
  }
  
  .vendor-response-form label {
    display: block;
    margin-bottom: 10px;
    color: #333;
  }
  
  .vendor-response-form .form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .vendor-response-form .form-field {
    flex: 1 1 22%; /* Adjust as needed to fit 4 items in a row */
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; /* Space between rows */
  }
  
  .vendor-response-form input,
  .vendor-response-form textarea,
  .vendor-response-form select {
    width: 100%; /* The input will take up the full width of its container */
    padding: 7px;
    margin-top: 5px; /* Adjust as needed */
    border: 1px solid #ccc;
    background-color: #100f0f08;
    border-radius: 4px;
  }
  
  .vendor-response-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
  }
  
  .vendor-response-form button:hover {
    background-color: #d1ddd2;
  }
  
  .vendor-response-form .required {
    color: red;
  }
  
  /* Responsive adjustments if necessary */
  @media (max-width: 768px) {
    .vendor-response-form .form-row {
      flex-direction: column;
    }
  
    .vendor-response-form .form-field {
      flex: 1 1 100%; /* Full width on small screens */
    }
    .vendor-response-form .required {
        color: red;
      }
  }
  .selected-container {
    background-color: #f1f1f1; 
    border: 1px solid #ccc; 
    padding: 15px; 
    margin-top: 10px;
  }

  .register-container {
    background-color: #f1f1f1; 
    border: 1px solid #ccc; 
    padding: 30px; 
    margin-top: 10px;
    margin-bottom : 10px;
  }

  /* Overlay styles when zoomed in */
.overlay {
  position: fixed; /* Overlay is positioned over the whole screen */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  align-items: center; /* Center the content vertically */
  justify-content: center; /* Center the content horizontally */
  z-index: 10; 
}

/* Hides the scrollbar to prevent scrolling when overlay is active */
body.overlay-active {
  overflow: hidden;
}
