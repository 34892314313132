/* Existing styles */
.dashboard {
  font-family: Arial, sans-serif;
  min-height: 100vh;
  /* padding: 2rem; */
}

.header {
  background-color: #ffffff;
  padding: 1rem 2rem;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .logo {
  font-size: 1.5rem;
  color: #0d0101;
}

.see-list {
  display: none;
  text-decoration: underline !important;
  text-underline-offset: 2px;
  /* Adjust this value as needed for more space */
  font-size: 12px;
  color: #025427;
  cursor: pointer;
}

.stat-item:hover .see-list {
  display: inline;
}

@media (max-width: 600px) {
  .stat-item p {
    margin-bottom: 20px;
  }

  .stat-item {
    padding-bottom: 50px;
  }

  .see-list {
    font-size: 10px;
  }
}

@media (max-width: 450px) {
  .stat-item p {
    margin-bottom: 50px;
  }

  .stat-item {
    padding-bottom: 70px;
  }

  .see-list {
    font-size: 7px;
  }
}

.main-content {
  padding-left: 2rem;
}

.summary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.summary-item {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 23%;
  text-align: center;
}

.summary-item h3 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #666;
}

.summary-item p {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.statistics {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}


.stat-container {
  display: flex;
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


.stat-item {
  background-color: #ecede347;
  padding: 0.5rem;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  box-shadow: 20px 20px 60px #00000041, inset -20px -20px 60px #ffffff47;
  text-align: center;
  width: 200px;
  margin-right: 1rem; 
  margin-bottom: 1rem;
  transition: transform 0.3s, box-shadow 0.3s;
  animation: slideInFromTop 1.0s ease-out;
  /* Add this line */
  border: 1px solid greenyellow;
}


.stat-item-empty {
  background-color: #fafafa;
  padding: 1rem;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  text-align: center;
  width: 200px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  transition: transform 0.3s, box-shadow 0.3s;
  animation: slideInFromTop 1.0s ease-out;
  /* Add this line */
}

/* .gradient-background {
   background: linear-gradient(to right , #292727, #938d84);
  padding: 10px; 
    border-radius: 10px; 
} */

.stat-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.small-image {
  width: 50px;
  height: 50px;
}

@media (max-width: 1230px) {
  .stat-item {
    width: 200px
  }
}

@media (max-width: 350px) {
  .stat-container {
    justify-content: center;
  }

  .stat-item {
    margin: 0;
  }
}


.stat-item2 {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 250px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  height: 190px;
  background: #d8e0e7;
}

.stat-item h3 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #025427;
}

.stat-item p {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: #025427;
}

.charts {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 1rem;
  overflow-x: auto;
  /* max-width: 300px; */
}

.chart-item {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f7f4f4;
  padding-top: 30px;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-bottom: 1rem;
  margin-top: 10px;
  /* height: 300px;
  width:300px */
}

.chart-title {
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 1.5rem;
  color: #333;
}

.chart-subtitle {
  margin-top: 20px;
  text-align: center;
  font-size: 1.2rem;
  color: #666;
}

.chart-container {
  width: 100%;
  background-color: transparent;
  padding: 1rem;
  border-radius: 10px;
  border: none;
  box-shadow: none;
}

.chart-item h3 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #666;
}

.small-image {
  width: 30px;
  height: auto;
  margin-bottom: 10px;
}

.other-content {
  display: flex;
  flex-direction: column;
}

.timeline-container {
  background: lightblue;
  padding: 10px;
  border-radius: 8px;
  width: 200%;
}

.timeline-item {
  margin-bottom: 20px;
}

.timeline-date {
  font-weight: bold;
  margin-bottom: 5px;
}

.timeline-description {
  font-size: 14px;
}

.cityCss {
  background-color: lightgray;
  width: 400px;
  margin-left: 20px;
  padding: 10px;
  border-radius: 10px;
}

/* Media queries for responsive design */

@media (max-width: 1024px) {
  .summary {
    flex-direction: column;
    align-items: center;
  }

  .other-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .summary-item {
    width: 80%;
    margin-bottom: 1rem;
  }

  /* .statistics {
      flex-direction: column;
      align-items: center;
  } */

  .stat-item {
    width: 20%;
  }

  .stat-item2 {
    width: 80%;
  }

  .charts {
    /* flex-direction: column;
    flex-wrap: wrap; */
    max-width: 720px;
  }

  .chart-item {
    width: calc(33.3333% - 1rem);
    min-width: 250px;
  }

  .timeline-container {
    display: none;
  }
}

@media (max-width : 540px) {

  .statistics,
  .charts {
    max-width: 500px;
  }

}

@media (max-width: 768px) {
  .main-content {
    padding: 1rem;
  }

  .stat-item {
    width: 40%;
    margin-right: 10px;
    /* margin-left: 20px; */
  }

  .stat-item2 {
    width: 100%;
    margin-right: 0;
  }

  .chart-item {
    width: 100%;
    min-width: unset;
  }

  .timeline-container {
    display: none;
  }
}

@media (max-width: 500px) {

  .statistics,
  .charts {
    /* flex-direction: column; */
    /* align-items: center; */
    align-items: center;
    max-width: 390px;
  }
}


@media (max-width: 400px) {
  .dashboard {
    /* padding: 1rem; */
  }

  /* .stat-container {
    justify-content: center;
  } */

  .small-image {
    width: 20px;
    height: 20px;
    margin-bottom: 10px;
  }

  .header {

    flex-direction: column;
    align-items: flex-start;
  }

  .header .logo {
    font-size: 1.2rem;
  }

  .summary-item {
    width: 100%;
  }

  .stat-item {
    width: 100%;
    margin-bottom: 1rem;
    padding-top: 1rem;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    /* margin-left: 10px; */
  }

  .stat-item h3 {
    font-size: 0.6rem;
    margin-bottom: 0.5rem;
    color: #025427;
  }

  .stat-item p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #025427;
  }

  .stat-item2 {
    width: 100%;
    margin-bottom: 1rem;
  }

  .chart-item {
    padding: 1rem;
    width: 95%;
    min-width: unset;
  }




  .timeline-container {
    display: none;
  }

  .cityCss {
    background-color: lightgray;
    width: 300px;
    /* margin-left: 20px; */
    padding: 10px;
    border-radius: 10px;
  }

  .statistics,
  .charts {
    /* flex-direction: column; */
    /* align-items: center; */
    align-items: center;
    max-width: 350px;
  }
}

.seehere-container {
  display: flex;
  justify-content: flex-end;
  /* Aligns the child element to the right */
}

.right-align {
  padding: 5px 5px;
  /* Add some padding for better appearance */
  text-decoration: none;
  /* Remove underline */
  color: blue;
  /* Set text color */
  cursor: pointer;
  /* Change cursor to pointer on hover */
  font-size: 10px;
  text-decoration: underline !important;
}

.right-align:hover {
  color: darkblue;
  /* Change color on hover for better UX */
}

.topdivs {
  background-color: #776e6e;
  border-radius: 10px;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  font-size: 10px;
  color: white;
}

.topdivs:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.topdivs.selected {
  background-color: rgb(247, 240, 12);
  color: black;
  font-weight: bold;
  box-shadow: 20px 20px 60px #00000041, inset -20px -20px, 60px #ffffff47
    /* Increase shadow on hover */


}

/* @keyframes shine {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
.stat-item.shine {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 215, 0, 0.6) 50%, rgba(255, 255, 255, 0) 100%);
  background-size: 200% 100%;
  animation: shine 2s infinite;
} */


@keyframes shine {
  0% {
    background-position: -200% 0;
    /* Start completely off-screen to the left */
  }

  100% {
    background-position: 200% 0;
    /* Move completely off-screen to the right */
  }
}


.stat-item.shine {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 215, 0, 0.6) 50%, rgba(255, 255, 255, 0) 100%);
  background-size: 200% 100%;
  /* Double the width to allow for animation */
  animation: shine 2s linear infinite;
  /* Adjust duration and set animation to linear */
}

.shine-payment-button {
  background: linear-gradient(90deg, rgba(192, 192, 192, 0.2) 0%, rgba(183, 220, 80, 0.8) 50%, rgba(192, 192, 192, 0.2) 100%);
  background-size: 200% 100%;
  /* Allows the animation to create a flowing shine */
  animation: shine 2s linear infinite;
  /* For a moving shine effect */
  color: white;
  /* Text color to contrast with the background */
  border-radius: 30px;
  padding: 10px 20px;
  text-align: center;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  /* Ensures sparkles stay within the button */
  cursor: pointer;
  border: 1px solid rgba(211, 211, 211, 0.8);
}

/* Moving shine animation */
@keyframes shine {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

/* Star-like sparkle effect */
.shine-payment-button::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 10%, rgba(255, 255, 255, 0) 60%);
  animation: sparkle 3s linear infinite;
  pointer-events: none;
  /* Makes it non-interactive */
  opacity: 0.5;
}

/* Sparkling star movement */
@keyframes sparkle {

  0%,
  100% {
    transform: translate(0, 0) rotate(0deg);
  }

  50% {
    transform: translate(20px, 20px) rotate(180deg);
  }
}

/* @media (max-width : 340px){
  .stat-item {
   padding-top: 1rem;
   padding-left: 0.1rem;
   padding-right: 0.1rem;
}
.stat-item h3 {
  font-size: 0.5rem;
  margin-bottom: 0.5rem;
  color: #666;
}

} */

/* 
<div>
<div className="stat-item" style={{ marginTop: '20px'}}>
    <img src={customerImage} className="small-image" alt="Vendor Types" />
    <h3>Number of Customer</h3>
    <p>{vendorData.length}</p>
</div>

<div className="stat-item" style={{ marginTop: '20px' }}>
    <img src={complaints} className="small-image" alt="Vendor Types" />
    <h3>Number Of Complaints</h3>
    <p>{allAccidentVehicleData.length}</p>
</div>
</div> */