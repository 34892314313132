.vendor-master-form {
  margin: 40ox 40px 0px ; /* This sets top margin to 30px and centers it horizontally */
  padding: 20px;
  width: 1080px; /* Ensures it doesn't grow beyond 1200px but can be less */
  box-shadow: 0 4px 8px rgba(38, 21, 21, 0.1);
  background-color: white;
}

  
  .vendor-master-form label {
    display: block;
    margin-bottom: 10px;
    color: #333;
  }
  
  .vendor-master-form .form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .vendor-master-form .form-field {
    flex: 1 1 22%; /* Adjust as needed to fit 4 items in a row */
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; /* Space between rows */
  }
  
  .vendor-master-form input,
  .vendor-master-form textarea,
  .vendor-master-form select {
    width: 100%; /* The input will take up the full width of its container */
    padding: 7px;
    margin-top: 5px; /* Adjust as needed */
    border: 1px solid #ccc;
    background-color: #100f0f08;
    /* border-radius: 4px; */
  }
  
  .vendor-master-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
  }
  
  .vendor-master-form button:hover {
    background-color: #45a049;
  }
  
  .vendor-master-form .required {
    color: red;
  }
  
  /* Responsive adjustments if necessary */
  @media (max-width: 768px) {
    .vendor-master-form .form-row {
      flex-direction: column;
    }
  
    .vendor-master-form .form-field {
      flex: 1 1 100%; /* Full width on small screens */
    }
    .vendor-master-form .required {
        color: red;
      }
  }
  