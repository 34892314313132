@media (max-width: 767.98px) { .border-sm-start-none { border-left: none !important; } }

.width-increase{
    width: 50%;
    margin-left: 30px;
}
@media (max-width: 880px) {
    .promo-banner {
        display: none;
    }
    .width-increase{
        margin-top: 40px;
        width: 80%;
    }
}
