undefined * {
    line-height: 1
}



*,
*::before,
*::after {
    box-sizing: border-box
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-weight: 300
}

a {
    color: inherit;
    text-decoration: inherit
}

.left {
    text-align: left
}

.right {
    text-align: right
}

.center {
    text-align: center;
    margin-left: auto;
    margin-right: auto
}

.justify {
    text-align: justify
}

/* .container {
    max-width: 1140px;
    width: 100% !important;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto !important;
    margin-left: auto !important
}

@media (min-width:576px) and (max-width:768px) {
     {
        max-width: 540px
    }
}

@media (min-width:768px) and (max-width:992px) {
    .container {
        max-width: 720px
    }
}

@media (min-width:992px) and (max-width:1200px) {
    .container {
        max-width: 960px
    }
} */

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    line-height: 1
}
body {
    font-size: 0;
    font-family: "Nunito Sans"
}

html {
    font-size: 100%
}

input,
textarea,
select {
    min-height: 20px;
    border: 0;
    font-size: 16px
}

.headerOne,
.headerTwo,
.headerThree,
.headerFour,
.headerFive,
.headerSix,
.paragraph {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 0.1rem 0;
    width: 100%
}

.lg-text-center-vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: inherit
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

body {
    line-height: 1
}

ol,
ul {
    list-style: none
}

blockquote,
q {
    quotes: none
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
    color: inherit;
    text-decoration: none;
    cursor: pointer
}

img {
    width: 100%;
    height: 100%;
    -o-object-fit: inherit;
    object-fit: inherit;
    border-radius: inherit
}

span {
    display: block
}

.home-elem-189:hover {
    color: #000000ff;
    background-color: #00000019
}

.home-elem-190:hover {
    color: #000000ff;
    background-color: #00000019
}

.home-elem-191:hover {
    color: #000000ff;
    background-color: #00000019
}

.home-elem-192:hover {
    color: #000000ff;
    background-color: #00000019
}

.home-elem-28::-moz-placeholder {
    color: #0000007f
}

.home-elem-28::placeholder {
    color: #0000007f
}

.home-elem-28:active {
    border-color: #d0021bff #d0021bff #d0021bff #d0021bff;
    border-width: 1px 1px 1px 1px
}

.home-elem-170::-moz-placeholder {
    color: #0000007f
}

.home-elem-170::placeholder {
    color: #0000007f
}

.home-elem-170:active {
    border-color: #d0021bff #d0021bff #d0021bff #d0021bff;
    border-width: 1px 1px 1px 1px
}

.home-elem-17 {
    cursor: pointer;
}


.home-elem-19 {
    padding: 0px 0px 20px 0px;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: auto;
    max-width: 1980px;
    position: relative;
}

.home-elem-1 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    align-items: center;
    background-color: #EFEFEF;
    margin-left: auto;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: auto;
    max-width: 1980px;
    position: relative
}

.home-elem-2 {
    padding: 10px 16px 10px 16px;
    flex-direction: row;
    justify-content: space-between;
    -moz-column-gap: 50px;
    column-gap: 50px;
    background-color: #EFEFEF;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 1232px;
    max-height: 40px;
    position: relative
}

.home-elem-3 {
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    -moz-column-gap: 10px;
    column-gap: 10px;
    height: auto;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    margin-right: NaNpx;
    position: relative
}

.home-elem-4 {
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    color: #656565;
    line-height: 24px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-7 {
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    -moz-column-gap: 10px;
    column-gap: 10px;
    height: auto;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    margin-right: NaNpx;
    position: relative
}

.home-elem-5 {
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    color: #656565;
    line-height: 24px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-6 {
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    color: #656565;
    line-height: 24px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-14 {
    padding: 0px 16px 20px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:rgb(2 54 20);
    z-index: 1200;
    width: 100%;
    height: -moz-fit-content;
    height: 70px;
    margin-left: auto;
    min-height: 20px;
    min-width: 20px;
    position: relative;
    display: flex;
    max-width: 1980px;
    margin-right: auto
}

.home-elem-13 {
    justify-content: space-between;
    align-items: center;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    max-width: 1200px;
    position: relative
}

.home-elem-195 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #00000000;
    background-image: url(https://res.cloudinary.com/dbyioi2qq/q_auto/v1688108670/static/component-126-2svg_1688108561_26947.svg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat no-repeat;
    background-attachment: scroll;
    background-clip: border-box;
    background-origin: padding-box;
    border-width: 0px 0px 0px 0px;
    border-style: solid solid solid solid;
    border-color: #000000 #000000 #000000 #000000;
    height: 15px;
    width: 35px;
    display: none;
    position: relative
}

.home-elem-15 {
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    position: relative
}

.logo-image {
    border-radius: 100px;
    width: 50px;
    margin-top: 30px;
    /* height: auto; */
  }

  .title {
    width: 250px;
    font-size: 20px;
    margin-top: 30px;

    /* font-size: 10px; */
  }
  
  @media (max-width: 600px) {
    .title {
        font-size: 10px;
    width: 150px;

    }
  }

  .menu-container {
    position: fixed;
    top: 10px;
    /* right: 10px; */
    z-index : 1000;
  }
  
  .menu-button {
    padding: 2px 2px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 85px;
    border-radius: 10px;
    margin-right: 10px;
    transition: background-color 0.3s, transform 0.3s;
  }

  .menu-button.clicked {
    background-color: teal; /* Change background color when clicked */
    color: white; /* Change text color for better contrast */
    transform: scale(1.2); /* Expand size of the button */
  }
  
  .menu-card {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50px;
    /* right: 0; */
    width: 150px;
    z-index: 1000;
    margin-top: 70px;
    margin-left: 10px;
    border-radius: 10px;
    font-size: 13px;
    font-weight: bold;
    
  }
  
  .menu-card ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .menu-card li {
    border-bottom: 1px solid #ccc;
  }
  
  .menu-card li:last-child {
    border-bottom: none;
  }
  
  .menu-card a {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: #333;
  }
  
  .menu-card a:hover {
    background-color: #f0f0f0;
  }
  





.home-elem-18 {
    font-family: Fraunces;
    font-size: 25px;
    font-weight: 700;
    color: #ffffffff;
    line-height: 35px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    gap:10px;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    
}

.home-elem-16 {
    justify-content: center;
    z-index: 1;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    min-width: 33%;
    position: relative
}

.home-elem-12 {
    margin-top: 20px;
    z-index: 1;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    position: relative
}

.home-elem-8 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #ffffffbf;
    line-height: 26px;
    padding: 12px 16px 12px 16px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-9 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #ffffffbf;
    line-height: 26px;
    padding: 12px 16px 12px 16px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-10 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #ffffffbf;
    line-height: 26px;
    padding: 12px 16px 12px 16px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-11 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #ffffffbf;
    line-height: 26px;
    padding: 12px 16px 12px 16px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

/* Existing styles */
.home-elem-17 {
    margin-top: 20px;
    padding: 6px 17px;
    background-color: #EEE;
    border-radius: 100px;
    border: 0;
    /* Simplified border property */
    border-style: groove;
    border-color: #333;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 700;
    color: #111;
    line-height: 24px;
    margin-right: 10px;
    width: fit-content;
    height: fit-content;
    position: relative;
}

.home-elem-17:hover {
    background-color: #cdc5c5; /* Darker background color on hover */
    border-color: #EEE; /* Lighter border color on hover */
    color: #EEE; /* Lighter text color on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Add shadow on hover */
    transform: scale(1.1); /* Slightly larger on hover */
}


.home-elem-194 {
    padding: 0px 16px 20px 16px;
    flex-direction: row;
    justify-content: center;
    background-color: #339657;
    z-index: 1;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: none;
    min-width: 33%;
    position: relative
}

.home-elem-193 {
    flex-direction: column;
    align-items: center;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    position: relative
}

.home-elem-189 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #ffffffbf;
    line-height: 26px;
    padding: 12px 16px 12px 16px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-190 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #ffffffbf;
    line-height: 26px;
    padding: 12px 16px 12px 16px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-191 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #ffffffbf;
    line-height: 26px;
    padding: 12px 16px 12px 16px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-192 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #ffffffbf;
    line-height: 26px;
    padding: 12px 16px 12px 16px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-196 {
    padding: 12px 26px 12px 26px;
    background-color: #EEE;
    border-radius: 100px 100px 100px 100px;
    border-width: 0px 0px 0px 0px;
    border-style: groove groove groove groove;
    border-color: #333 #333 #333 #333;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 700;
    color: #111;
    line-height: 24px;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    position: relative
}

.home-elem-20 {
    padding: 100px 16px 100px 56px;
    flex-direction: row;
    -moz-column-gap: 50px;
    column-gap: 50px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    /* max-width: 1232px; */
    position: relative;
    /* background: linear-gradient(to right, rgb(192, 198, 240), transparent); */
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)),
                url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="%23a2d9ff" fill-opacity="0.5" d="M0,224L10.4,218.7C20.9,213,42,203,63,192C83.5,181,104,171,125,144C146.1,117,167,75,188,69.3C208.7,64,230,96,250,112C271.3,128,292,128,313,138.7C333.9,149,355,171,376,202.7C396.5,235,417,277,438,293.3C459.1,309,480,299,501,250.7C521.7,203,543,117,563,85.3C584.3,53,605,75,626,101.3C647,128,668,160,689,149.3C709.6,139,730,85,751,85.3C772.2,85,793,139,814,133.3C834.8,128,856,64,877,42.7C897.4,21,918,43,939,48C960,53,981,43,1002,64C1022.6,85,1043,139,1064,160C1085.2,181,1106,171,1127,165.3C1147.8,160,1169,160,1190,149.3C1210.4,139,1231,117,1252,144C1273,171,1294,245,1315,245.3C1335.7,245,1357,171,1377,160C1398.3,149,1419,203,1430,229.3L1440,256L1440,320L1429.6,320C1419.1,320,1398,320,1377,320C1356.5,320,1336,320,1315,320C1293.9,320,1273,320,1252,320C1231.3,320,1210,320,1190,320C1168.7,320,1148,320,1127,320C1106.1,320,1085,320,1064,320C1043.5,320,1023,320,1002,320C980.9,320,960,320,939,320C918.3,320,897,320,877,320C855.7,320,835,320,814,320C793,320,772,320,751,320C730.4,320,710,320,689,320C667.8,320,647,320,626,320C605.2,320,584,320,563,320C542.6,320,522,320,501,320C480,320,459,320,438,320C417.4,320,397,320,376,320C354.8,320,334,320,313,320C292.2,320,271,320,250,320C229.6,320,209,320,188,320C167,320,146,320,125,320C104.3,320,83,320,63,320C41.7,320,21,320,10,320L0,320Z"></path></svg>') no-repeat;
    background-size: cover;
}

.home-elem-21 {
    padding: 0px 0px 10px 0px;
    flex-direction: column;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 575px;
    position: relative
}

.home-elem-22 {
    padding: 5px 15px 5px 15px;
    flex-direction: row;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
    background-color: #EFEFEF;
    border-radius: 100px 100px 100px 100px;
    height: auto;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    margin-right: NaNpx;
    position: relative
}

.home-elem-23 {
    padding: 0px 0px 0px 0px;
    color: #339657;
    text-align: left;
    font-size: 30px;
    line-height: 40px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-24 {
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    line-height: 24px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-25 {
    font-family: 'Fraunces', serif;
    font-size: 40px;
    font-weight: 700;
    color: #000;
    line-height: 60px;
    padding: 25px 0 20px;
    opacity: 100%;
    width: fit-content;
    height: fit-content;
    display: block;
    position: relative;
    overflow: hidden;
  }  

  
  
  .home-elem-25 h1 {
    position: relative;
    z-index: 1;
  }
  
  .home-elem-25::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, lightblue, transparent);
    clip-path: path('M0,0 C50,100 150,0 300,100 L300,0 Z'); /* Define your wave path */
    z-index: -1; /* Position the wave background behind the text */
  }
  

.home-elem-26 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 400;
    color: #656565;
    line-height: 28px;
    padding: 0px 0px 30px 0px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}


.home-elem-27 {
    padding: 10px 10px 10px 10px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    -moz-column-gap: 20px;
    column-gap: 20px;
    background-color: #ffffff;
    border-radius: 100px 100px 100px 100px;
    height: auto;
    /* width: 100%; */
    display: flex;
    margin-right: NaNpx;
    max-width: 190px;
    position: relative
}


.home-elem-28 {
    padding: 8px 16px 8px 12px;
    font-family: Fraunces;
    font-size: 16px;
    color: #000000;
    line-height: 26px;
    background-color: #ffffff00;
    border: 1px solid #cccccc;
    border-radius: 4px 4px 4px 4px;
    border-width: 0px 0px 0px 0px;
    border-style: solid solid solid solid;
    border-color: #E2E2E2 #E2E2E2 #E2E2E2 #E2E2E2;
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    max-width: 300px
}

.home-elem-29 {
    padding: 12px 26px 12px 26px;
    background-color: green;
    border-radius: 100px 100px 100px 100px;
    border-width: 0px 0px 0px 0px;
    border-style: groove groove groove groove;
    border-color: #333 #333 #333 #333;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 700;
    color: #ffffffff;
    line-height: 26px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative
}

.home-elem-30 {
    padding: 0px 0px 20px 0px;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 575px;
    position: relative
}

.home-elem-31 {
    padding: 10px 10px 10px 10px;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffffff;
    box-shadow: 8px 8px 30px 0px #00000014;
    border-radius: 16px 16px 16px 16px;
    align-items: center;
    z-index: 1;
    margin-left: -50px;
    height: 150px;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 155px;
    margin-bottom: -150px;
    position: relative
}

.home-elem-32 {
    padding: 10px 10px 10px 10px;
    flex-direction: column;
    justify-content: center;
    border-radius: 100px 100px 100px 100px;
    border-width: 10px 10px 10px 10px;
    border-style: solid solid solid solid;
    border-color: #FFCE31 #FFCE31 #FFCE31 #EFEFEF;
    align-items: center;
    height: 100px;
    width: 100px;
    display: flex;
    position: relative
}

.home-elem-33 {
    font-family: Fraunces;
    font-size: 25px;
    font-weight: 700;
    color: #000;
    line-height: 35px;
    padding: 0px 0px 0px 0px;
    text-align: center;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-34 {
    font-family: Fraunces;
    font-size: 12px;
    font-weight: 400;
    color: #000;
    line-height: 22px;
    padding: 0px 0px 0px 0px;
    text-align: center;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-35 {
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    align-items: flex-end;
    -moz-column-gap: 20px;
    column-gap: 20px;
    z-index: 0;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    position: relative
}

.home-elem-36 {
    border-radius: 20px 20px 20px 20px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 320px;
    position: relative;
    max-width: 225px;
}

.home-elem-37 {
    border-radius: 20px;
    object-fit: cover;
    width: 100%;
    height: 420px;
    position: relative;
    max-width: 330px;
    opacity: 0; /* Start hidden */
    transform: translateX(100px); /* Start off-screen to the right */
    transition: transform 1s ease-out, opacity 1s ease-out; /* Transition effect */
  }
  
  .home-elem-37.visible {
    opacity: 1; /* Fully visible */
    transform: translateX(0); /* Move to original position */
  }
  

.home-elem-38 {
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    align-items: flex-end;
    -moz-column-gap: 20px;
    column-gap: 20px;
    margin-top: -50px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: none;
    position: relative
}

.home-elem-39 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    line-height: 26px;
    padding: 20px 20px 20px 20px;
    background-color: #ffffffff;
    box-shadow: 0px 8px 30px 0px #0000000f;
    border-radius: 6px 6px 6px 6px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-40 {
    padding: 20px 15px 20px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    -moz-column-gap: 10px;
    column-gap: 10px;
    background-color: rgb(0 127 44);
    border-radius: 20px 20px 20px 20px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 280px;
    position: relative
}

.home-elem-41 {
    padding: 0px 0px 0px 0px;
    color: #ffffffff;
    text-align: left;
    font-size: 25px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-42 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #ffffffff;
    line-height: 26px;
    padding: 0px 0px 0px 20px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 200px
}

.home-elem-44 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    align-items: flex-end;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    position: relative
}

.home-elem-43 {
    padding: 0px 0px 0px 0px;
    color: #ffffffff;
    text-align: left;
    font-size: 25px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-45 {
    padding: 30px 16px 45px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #262626;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    position: relative
}

.home-elem-46 {
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    align-items: center;
    -moz-column-gap: 50px;
    column-gap: 50px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 1200px;
    position: relative
}

.home-elem-47 {
    font-family: Fraunces;
    font-size: 36px;
    font-weight: 600;
    color: #ffffffff;
    line-height: 46px;
    padding: 25px 0px 20px 0px;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 575px
}


.home-elem-48 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 575px;
    position: relative
}


.home-elem-49 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #ffffffff;
    line-height: 26px;
    padding: 25px 0px 20px 0px;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 575px
}

.home-elem-50 {
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    -moz-column-gap: 35px;
    column-gap: 35px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    position: relative
}

.home-elem-51 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    margin-right: NaNpx;
    position: relative
}

.home-elem-52 {
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 300;
    color: #ffffffff;
    line-height: 24px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-58 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    margin-right: NaNpx;
    position: relative
}

.home-elem-57 {
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 300;
    color: #ffffffff;
    line-height: 24px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-56 {
    font-family: Fraunces;
    font-size: 25px;
    font-weight: 700;
    color: #ffffffff;
    line-height: 35px;
    padding: 0px 0px 0px 0px;
    text-align: center;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-55 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    margin-right: NaNpx;
    position: relative
}

.home-elem-54 {
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 300;
    color: #ffffffff;
    line-height: 24px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-53 {
    font-family: Fraunces;
    font-size: 25px;
    font-weight: 700;
    color: #ffffffff;
    line-height: 35px;
    padding: 0px 0px 0px 0px;
    text-align: center;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-59 {
    padding: 50px 16px;
    padding-bottom: 20px;
    flex-direction: row;
    column-gap: 50px;
    height: auto;
    /* width: 100%; */
    display: flex;
    margin-right: 0; /* Ensure margin-right is 0 */
    max-width: 1232px;
    position: relative;
    opacity: 0; /* Start hidden */
    transform: translateX(-100px); /* Start off-screen to the left */
    transition: transform 1s ease-out, opacity 1s ease-out; /* Transition effect */

}

.home-elem-59.visible {
    opacity: 1; /* Fully visible */
    transform: translateX(0); /* Move to original position */
}

.home-elem-60 {
    /* padding: 20px 20px 0px 20px; */
    /* flex-direction: column; */
    align-items: center;
    background-color: #F4F4F4;
    /* border-radius: 40px 40px 40px 40px; */
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 575px;
    /* min-height: 550px; */
    position: relative;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* padding-top: 90px; */
}

.home-elem-61 {
    -o-object-fit: cover;
    object-fit: cover;
    width: -moz-fit-content;
    width: fit-content;
    height: 100%;
    position: relative
}

.home-elem-62 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    justify-content: center;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 675px;
    
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="%23FFFFE0" fill-opacity="0.5" d="M0,224L10.4,218.7C20.9,213,42,203,63,192C83.5,181,104,171,125,144C146.1,117,167,75,188,69.3C208.7,64,230,96,250,112C271.3,128,292,128,313,138.7C333.9,149,355,171,376,202.7C396.5,235,417,277,438,293.3C459.1,309,480,299,501,250.7C521.7,203,543,117,563,85.3C584.3,53,605,75,626,101.3C647,128,668,160,689,149.3C709.6,139,730,85,751,85.3C772.2,85,793,139,814,133.3C834.8,128,856,64,877,42.7C897.4,21,918,43,939,48C960,53,981,43,1002,64C1022.6,85,1043,139,1064,160C1085.2,181,1106,171,1127,165.3C1147.8,160,1169,160,1190,149.3C1210.4,139,1231,117,1252,144C1273,171,1294,245,1315,245.3C1335.7,245,1357,171,1377,160C1398.3,149,1419,203,1430,229.3L1440,256L1440,320L1429.6,320C1419.1,320,1398,320,1377,320C1356.5,320,1336,320,1315,320C1293.9,320,1273,320,1252,320C1231.3,320,1210,320,1190,320C1168.7,320,1148,320,1127,320C1106.1,320,1085,320,1064,320C1043.5,320,1023,320,1002,320C980.9,320,960,320,939,320C918.3,320,897,320,877,320C855.7,320,835,320,814,320C793,320,772,320,751,320C730.4,320,710,320,689,320C667.8,320,647,320,626,320C605.2,320,584,320,563,320C542.6,320,522,320,501,320C480,320,459,320,438,320C417.4,320,397,320,376,320C354.8,320,334,320,313,320C292.2,320,271,320,250,320C229.6,320,209,320,188,320C167,320,146,320,125,320C104.3,320,83,320,63,320C41.7,320,21,320,10,320L0,320Z"></path></svg>') no-repeat;
    background-size: cover;
}

.home-elem-63 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 700;
    color: #000000ff;
    line-height: 36px;
    padding: 20px 0px 20px 20px;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 550px;
    background: linear-gradient(to right, rgb(237, 224, 224), transparent);
    border-radius: 20px;
}

.home-elem-64 {
    font-family: Fraunces;
    /* font-size: 18px; */
    font-weight: 400;
    color: #656565;
    line-height: 28px;
    padding: 0px 0px 35px 0px;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 500px;
    margin-top: 10px;
    padding-left: 10px;
}

.home-elem-65 {
    padding: 12px 26px 12px 26px;
    background-color: #0e4823ff;
    border-radius: 100px 100px 100px 100px;
    border-width: 0px 0px 0px 0px;
    border-style: groove groove groove groove;
    border-color: #333 #333 #333 #333;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 700;
    color: #ffffffff;
    line-height: 26px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative
}

.home-elem-79 {
    padding: 10px 16px 20px 16px;
    flex-direction: row;
    justify-content: flex-end;
    -moz-column-gap: 50px;
    column-gap: 50px;
    height: auto;
    /* width: 100%; */
    display: flex;
    margin-right: NaNpx;
    max-width: 1232x;
    position: relative;
    opacity: 0; /* Start hidden */
    transform: translateX(100px); /* Start off-screen to the left */
    transition: transform 1s ease-out, opacity 1s ease-out; /* Transition effect */
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="%23CCFFCC" fill-opacity="0.5" d="M0,224L10.4,218.7C20.9,213,42,203,63,192C83.5,181,104,171,125,144C146.1,117,167,75,188,69.3C208.7,64,230,96,250,112C271.3,128,292,128,313,138.7C333.9,149,355,171,376,202.7C396.5,235,417,277,438,293.3C459.1,309,480,299,501,250.7C521.7,203,543,117,563,85.3C584.3,53,605,75,626,101.3C647,128,668,160,689,149.3C709.6,139,730,85,751,85.3C772.2,85,793,139,814,133.3C834.8,128,856,64,877,42.7C897.4,21,918,43,939,48C960,53,981,43,1002,64C1022.6,85,1043,139,1064,160C1085.2,181,1106,171,1127,165.3C1147.8,160,1169,160,1190,149.3C1210.4,139,1231,117,1252,144C1273,171,1294,245,1315,245.3C1335.7,245,1357,171,1377,160C1398.3,149,1419,203,1430,229.3L1440,256L1440,320L1429.6,320C1419.1,320,1398,320,1377,320C1356.5,320,1336,320,1315,320C1293.9,320,1273,320,1252,320C1231.3,320,1210,320,1190,320C1168.7,320,1148,320,1127,320C1106.1,320,1085,320,1064,320C1043.5,320,1023,320,1002,320C980.9,320,960,320,939,320C918.3,320,897,320,877,320C855.7,320,835,320,814,320C793,320,772,320,751,320C730.4,320,710,320,689,320C667.8,320,647,320,626,320C605.2,320,584,320,563,320C542.6,320,522,320,501,320C480,320,459,320,438,320C417.4,320,397,320,376,320C354.8,320,334,320,313,320C292.2,320,271,320,250,320C229.6,320,209,320,188,320C167,320,146,320,125,320C104.3,320,83,320,63,320C41.7,320,21,320,10,320L0,320Z"></path></svg>') no-repeat;
    background-size: cover;

}

.home-elem-79.visible {
    opacity: 1; /* Fully visible */
    transform: translateX(0); /* Move to original position */
} 

.home-elem-78 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    justify-content: center;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 575px;
    position: relative;
    /* background:linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="%23CCFFCC" fill-opacity="0.5" d="M0,224L10.4,218.7C20.9,213,42,203,63,192C83.5,181,104,171,125,144C146.1,117,167,75,188,69.3C208.7,64,230,96,250,112C271.3,128,292,128,313,138.7C333.9,149,355,171,376,202.7C396.5,235,417,277,438,293.3C459.1,309,480,299,501,250.7C521.7,203,543,117,563,85.3C584.3,53,605,75,626,101.3C647,128,668,160,689,149.3C709.6,139,730,85,751,85.3C772.2,85,793,139,814,133.3C834.8,128,856,64,877,42.7C897.4,21,918,43,939,48C960,53,981,43,1002,64C1022.6,85,1043,139,1064,160C1085.2,181,1106,171,1127,165.3C1147.8,160,1169,160,1190,149.3C1210.4,139,1231,117,1252,144C1273,171,1294,245,1315,245.3C1335.7,245,1357,171,1377,160C1398.3,149,1419,203,1430,229.3L1440,256L1440,320L1429.6,320C1419.1,320,1398,320,1377,320C1356.5,320,1336,320,1315,320C1293.9,320,1273,320,1252,320C1231.3,320,1210,320,1190,320C1168.7,320,1148,320,1127,320C1106.1,320,1085,320,1064,320C1043.5,320,1023,320,1002,320C980.9,320,960,320,939,320C918.3,320,897,320,877,320C855.7,320,835,320,814,320C793,320,772,320,751,320C730.4,320,710,320,689,320C667.8,320,647,320,626,320C605.2,320,584,320,563,320C542.6,320,522,320,501,320C480,320,459,320,438,320C417.4,320,397,320,376,320C354.8,320,334,320,313,320C292.2,320,271,320,250,320C229.6,320,209,320,188,320C167,320,146,320,125,320C104.3,320,83,320,63,320C41.7,320,21,320,10,320L0,320Z"></path></svg>') no-repeat; */
    background-size: cover;
}

.home-elem-75 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 700;
    color: #000000ff;
    line-height: 36px;
    padding: 20px 0px 20px 20px;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 550px;
    background: linear-gradient(to right, rgb(192, 198, 240), transparent);
    border-radius: 20px;
}

.home-elem-76 {
    font-family: Fraunces;
    /* font-size: 18px; */
    font-weight: 400;
    color: #656565;
    line-height: 28px;
    padding: 0px 0px 35px 0px;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 500px;
    margin-top: 10px;
    padding-left: 10px;
}

.home-elem-77 {
    padding: 12px 26px 12px 26px;
    background-color: #0e4823ff;
    border-radius: 100px 100px 100px 100px;
    border-width: 0px 0px 0px 0px;
    border-style: groove groove groove groove;
    border-color: #333 #333 #333 #333;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 700;
    color: #ffffffff;
    line-height: 26px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative
}

.home-elem-74 {
    padding: 40px 40px 0px 40px;
    flex-direction: column;
    align-items: center;
    background-color: #F4F4F4;
    border-radius: 40px 40px 40px 40px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 575px;
    min-height: 550px;
    position: relative
}

.home-elem-73 {
    -o-object-fit: cover;
    /* object-fit: cover; */
    width: -moz-fit-content;
    width: fit-content;
    height: 100%;
    position: relative
}

.home-elem-72 {
    padding: 100px 16px 100px 16px;
    flex-direction: row;
    -moz-column-gap: 50px;
    column-gap: 50px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 1232px;
    position: relative
}


.home-elem-67 {
    padding: 40px 40px 0px 40px;
    flex-direction: column;
    align-items: center;
    background-color: #F4F4F4;
    border-radius: 40px 40px 40px 40px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 575px;
    min-height: 550px;
    position: relative
}

.home-elem-66 {
    -o-object-fit: cover;
    cursor: pointer;
    /* object-fit: cover; */
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    position: relative;
    border-radius: 10px;
    box-shadow: 20px 20px 60px #00000041,inset -20px -20px 60px #ffffff47; /* Adjust shadow as needed */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

.home-elem-66:hover {
    transform: scale(1.05); /* Slightly enlarges the element */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}


.home-elem-71 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    justify-content: center;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 575px;
    position: relative
}

.home-elem-68 {
    font-family: Fraunces;
    font-size: 36px;
    font-weight: 700;
    color: #000000ff;
    line-height: 46px;
    padding: 0px 0px 20px 0px;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 550px
}

.home-elem-69 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 400;
    color: #656565;
    line-height: 28px;
    padding: 0px 0px 35px 0px;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 500px
}

.home-elem-70 {
    padding: 12px 26px 12px 26px;
    background-color: #0e4823ff;
    border-radius: 100px 100px 100px 100px;
    border-width: 0px 0px 0px 0px;
    border-style: groove groove groove groove;
    border-color: #333 #333 #333 #333;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 700;
    color: #ffffffff;
    line-height: 26px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative
}

.home-elem-80 {
    padding: 40px 16px 40px 16px;
    flex-direction: column;
    align-items: center;
    background-color: #8ccaef;
    margin-left: auto;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: auto;
    max-width: 1980px;
    position: relative
}



.home-elem-82 {
    font-family: Fraunces;
    font-size: 36px;
    font-weight: 700;
    color: #000000ff;
    line-height: 46px;
    padding: 0px 0px 20px 0px;
    text-align: center;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-83 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 400;
    color: #656565;
    line-height: 28px;
    padding: 0px 0px 35px 0px;
    text-align: center;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}



.home-elem-81 {
    padding: 20px 0px 0px 0px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    -moz-column-gap: 20px;
    column-gap: 20px;
    row-gap: 20px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 1200px;
    position: relative;
    opacity: 0; /* Start hidden */
    transform: translateY(-100%); /* Start off-screen above */
    transition: opacity 1s ease-out, transform 1s ease-out; /* Smooth transition */
}

.home-elem-81.visible {
    animation: slideInFromTop 1s ease-out forwards; /* Apply animation */
}


.home-elem-84 {
    padding: 0px 20px 0px 20px;
    flex-direction: column;
    background-color: #ffffffff;
    border-radius: 14px 14px 14px 14px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 385px;
    position: relative;
    box-shadow: 20px 20px 60px #00000041 inset, -20px -20px 60px #ffffff47;
}

.home-elem-85 {
    padding: 0px 0px 0px 0px;
    color: #339657;
    text-align: left;
    font-size: 22px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-86 {
    font-family: Fraunces;
    font-size: 22px;
    font-weight: 600;
    color: #000000ff;
    line-height: 32px;
    padding: 20px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-87 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #656565;
    line-height: 26px;
    padding: 0px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-95 {
    padding: 0px 20px 0px 20px;
    flex-direction: column;
    background-color: #6b557d;
    border-radius: 14px 14px 14px 14px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 385px;
    position: relative;
    box-shadow: 20px 20px 60px #00000041 inset, -20px -20px 60px #ffffff47;
}

.home-elem-92 {
    padding: 0px 0px 0px 0px;
    color: #ffffffff;
    text-align: left;
    font-size: 22px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-93 {
    font-family: Fraunces;
    font-size: 22px;
    font-weight: 600;
    color: #ffffffff;
    line-height: 32px;
    padding: 20px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-94 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #ffffffff;
    line-height: 26px;
    padding: 0px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-91 {
    padding: 0px 20px 10px 20px;
    flex-direction: column;
    background-color: #ffffffff;
    border-radius: 14px 14px 14px 14px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 385px;
    position: relative;
    box-shadow: 20px 20px 60px #00000041 inset, -20px -20px 60px #ffffff47;
}

.home-elem-88 {
    padding: 0px 0px 0px 0px;
    color: #339657;
    text-align: left;
    font-size: 22px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-89 {
    font-family: Fraunces;
    font-size: 22px;
    font-weight: 600;
    color: #000000ff;
    line-height: 32px;
    padding: 20px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-90 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #656565;
    line-height: 26px;
    padding: 0px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-107 {
    padding: 30px 20px 30px 20px;
    flex-direction: column;
    background-color: #0e4823ff;
    border-radius: 14px 14px 14px 14px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 385px;
    position: relative;
    color:white
}

.home-elem-104 {
    padding: 0px 0px 0px 0px;
    color: #339657;
    text-align: left;
    font-size: 22px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-105 {
    font-family: Fraunces;
    font-size: 22px;
    font-weight: 600;
    color: #000000ff;
    line-height: 32px;
    padding: 20px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-106 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #656565;
    line-height: 26px;
    padding: 0px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-103 {
    padding: 0px 20px 10px 20px;
    flex-direction: column;
    background-color: #ffffffff;
    border-radius: 14px 14px 14px 14px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 385px;
    position: relative;
}

.home-elem-100 {
    padding: 0px 0px 0px 0px;
    color: #339657;
    text-align: left;
    font-size: 22px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-101 {
    font-family: Fraunces;
    font-size: 22px;
    font-weight: 600;
    color: #000000ff;
    line-height: 32px;
    padding: 20px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-102 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #656565;
    line-height: 26px;
    padding: 0px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-99 {
    padding: 30px 20px 30px 20px;
    flex-direction: column;
    background-color: #ffffffff;
    border-radius: 14px 14px 14px 14px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 385px;
    position: relative
}

.home-elem-96 {
    padding: 0px 0px 0px 0px;
    color: #339657;
    text-align: left;
    font-size: 22px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-97 {
    font-family: Fraunces;
    font-size: 22px;
    font-weight: 600;
    color: #000000ff;
    line-height: 32px;
    padding: 20px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-98 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #656565;
    line-height: 26px;
    padding: 0px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-112 {
    padding: 100px 16px 100px 16px;
    flex-direction: column;
    align-items: center;
    background-color: #1F1F1F;
    margin-left: auto;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: auto;
    max-width: 1980px;
    position: relative
}

@media (max-width: 480px) {
    .home-elem-112 {
        display: none;
    }

    /* Additional styles for container or parent elements */
    .container-or-parent-selector {
        overflow-x: visible;
        /* Ensures nothing is hidden due to overflow */
        white-space: nowrap;
        /* Prevents wrapping which could hide elements */
    }
}

.home-elem-113 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 1200px;
    position: relative
}

.home-elem-111 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 400;
    color: #339657;
    line-height: 28px;
    padding: 0px 0px 15px 0px;
    text-align: center;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: 353.9249793899423px;
    margin-top: 825.9917728836044px;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-110 {
    font-family: Fraunces;
    font-size: 36px;
    font-weight: 700;
    color: #ffffffff;
    line-height: 46px;
    padding: 0px 0px 20px 0px;
    text-align: center;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: 206.35201978565541px;
    margin-top: 766.1112774049361px;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-109 {
    padding: 50px 0px 0px 0px;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    -moz-column-gap: 50px;
    column-gap: 50px;
    row-gap: 20px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 1200px;
    position: relative
}

.home-elem-125 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    row-gap: 20px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 650px;
    position: relative
}

.home-elem-114 {
    padding: 20px 20px 20px 20px;
    flex-direction: row;
    background-color: #252525;
    border-radius: 20px 20px 20px 20px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 650px;
    position: relative
}

.home-elem-115 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 500px;
    position: relative
}

.home-elem-108 {
    font-family: Fraunces;
    font-size: 24px;
    font-weight: 600;
    color: #ffffffff;
    line-height: 34px;
    padding: 0px 0px 5px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-116 {
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    -moz-column-gap: 2px;
    column-gap: 2px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    position: relative
}

.home-elem-117 {
    padding: 0px 0px 0px 0px;
    color: #f5a623ff;
    text-align: left;
    font-size: 15px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-121 {
    padding: 0px 0px 0px 0px;
    color: #f5a623ff;
    text-align: left;
    font-size: 15px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-120 {
    padding: 0px 0px 0px 0px;
    color: #f5a623ff;
    text-align: left;
    font-size: 15px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-119 {
    padding: 0px 0px 0px 0px;
    color: #f5a623ff;
    text-align: left;
    font-size: 15px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-118 {
    padding: 0px 0px 0px 0px;
    color: #f5a623ff;
    text-align: left;
    font-size: 15px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-122 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 400;
    color: #656565;
    line-height: 28px;
    padding: 20px 0px 0px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-123 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 400;
    color: #656565;
    line-height: 28px;
    padding: 20px 0px 0px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-124 {
    padding: 0px 0px 0px 0px;
    color: #ffffffff;
    text-align: left;
    font-size: 100px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-149 {
    padding: 20px 20px 20px 20px;
    flex-direction: row;
    background-color: #252525;
    border-radius: 20px 20px 20px 20px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 650px;
    position: relative
}

.home-elem-147 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    height: auto;
    width: 100%;
    display: flex;
    max-width: 500px;
    position: relative
}

.home-elem-138 {
    font-family: Fraunces;
    font-size: 24px;
    font-weight: 600;
    color: #ffffffff;
    line-height: 34px;
    padding: 0px 0px 5px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-144 {
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    -moz-column-gap: 2px;
    column-gap: 2px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    position: relative
}

.home-elem-139 {
    padding: 0px 0px 0px 0px;
    color: #f5a623ff;
    text-align: left;
    font-size: 15px;
    line-height: 25px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-143 {
    padding: 0px 0px 0px 0px;
    color: #f5a623ff;
    text-align: left;
    font-size: 15px;
    line-height: 25px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-142 {
    padding: 0px 0px 0px 0px;
    color: #f5a623ff;
    text-align: left;
    font-size: 15px;
    line-height: 25px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-141 {
    padding: 0px 0px 0px 0px;
    color: #f5a623ff;
    text-align: left;
    font-size: 15px;
    line-height: 25px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-140 {
    padding: 0px 0px 0px 0px;
    color: #f5a623ff;
    text-align: left;
    font-size: 15px;
    line-height: 25px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-145 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 400;
    color: #656565;
    line-height: 28px;
    padding: 20px 0px 0px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-146 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 400;
    color: #656565;
    line-height: 28px;
    padding: 20px 0px 0px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-148 {
    padding: 0px 0px 0px 0px;
    color: #ffffffff;
    text-align: left;
    font-size: 100px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-137 {
    padding: 20px 20px 20px 20px;
    flex-direction: row;
    background-color: #252525;
    border-radius: 20px 20px 20px 20px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 650px;
    position: relative
}

.home-elem-135 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    height: auto;
    width: 100%;
    display: flex;
    max-width: 500px;
    position: relative
}

.home-elem-126 {
    font-family: Fraunces;
    font-size: 24px;
    font-weight: 600;
    color: #ffffffff;
    line-height: 34px;
    padding: 0px 0px 5px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-132 {
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    -moz-column-gap: 2px;
    column-gap: 2px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    position: relative
}

.home-elem-127 {
    padding: 0px 0px 0px 0px;
    color: #f5a623ff;
    text-align: left;
    font-size: 15px;
    line-height: 25px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-131 {
    padding: 0px 0px 0px 0px;
    color: #f5a623ff;
    text-align: left;
    font-size: 15px;
    line-height: 25px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-130 {
    padding: 0px 0px 0px 0px;
    color: #f5a623ff;
    text-align: left;
    font-size: 15px;
    line-height: 25px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-129 {
    padding: 0px 0px 0px 0px;
    color: #f5a623ff;
    text-align: left;
    font-size: 15px;
    line-height: 25px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-128 {
    padding: 0px 0px 0px 0px;
    color: #f5a623ff;
    text-align: left;
    font-size: 15px;
    line-height: 25px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-133 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 400;
    color: #656565;
    line-height: 28px;
    padding: 20px 0px 0px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-134 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 400;
    color: #656565;
    line-height: 28px;
    padding: 20px 0px 0px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-136 {
    padding: 0px 0px 0px 0px;
    color: #ffffffff;
    text-align: left;
    font-size: 100px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-156 {
    padding: 100px 16px 100px 16px;
    flex-direction: column;
    align-items: center;
    background-color: #EFEFEF;
    margin-left: auto;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: auto;
    max-width: 1980px;
    position: relative
}

.home-elem-157 {
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    -moz-column-gap: 50px;
    column-gap: 50px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 1200px;
    position: relative
}

.home-elem-154 {
    font-family: Fraunces;
    font-size: 36px;
    font-weight: 700;
    color: #000000ff;
    line-height: 46px;
    padding: 0px 0px 0px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-155 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 400;
    color: #000000ff;
    line-height: 28px;
    padding: 0px 0px 0px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-153 {
    padding: 60px 0px 0px 0px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    -moz-column-gap: 10px;
    column-gap: 10px;
    row-gap: 20px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 1200px;
    position: relative
}

.home-elem-152 {
    padding: 30px 20px 30px 20px;
    flex-direction: column;
    background-color: #ffffffff;
    border-radius: 14px 14px 14px 14px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 385px;
    position: relative;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.home-elem-152:hover {
background-color: #FFD580;
transform: translateY(-5px);
box-shadow: 0 4px 8px rgba(0,0,0,0.2) ;
}

.home-elem-158 {
    border-radius: 10px 10px 0px 0px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 250px;
    position: relative
}

.home-elem-150 {
    font-family: Fraunces;
    font-size: 24px;
    font-weight: 600;
    color: #000000ff;
    line-height: 34px;
    padding: 20px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-151 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #656565;
    line-height: 26px;
    padding: 0px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-166 {
    padding: 30px 20px 30px 20px;
    flex-direction: column;
    background-color: #ffffffff;
    border-radius: 14px 14px 14px 14px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 385px;
    position: relative;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.home-elem-166:hover {
    background-color: #FFD580;
    transform: translateY(-5px);
    box-shadow: 0 4px 8px (0,0,0,0.2);
}

.home-elem-165 {
    border-radius: 10px 10px 0px 0px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 250px;
    position: relative
}

.home-elem-163 {
    font-family: Fraunces;
    font-size: 24px;
    font-weight: 600;
    color: #000000ff;
    line-height: 34px;
    padding: 20px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-164 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #656565;
    line-height: 26px;
    padding: 0px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-162 {
    padding: 30px 20px 30px 20px;
    flex-direction: column;
    background-color: #ffffffff;
    border-radius: 14px 14px 14px 14px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 385px;
    position: relative;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.home-elem-162:hover {
    background-color: #FFD580;
    transform: translateY(-5px);
    box-shadow: 0 4px 8px (0,0,0,0,2);
}

.home-elem-161 {
    border-radius: 10px 10px 0px 0px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 250px;
    position: relative
}

.home-elem-159 {
    font-family: Fraunces;
    font-size: 24px;
    font-weight: 600;
    color: #000000ff;
    line-height: 34px;
    padding: 20px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-160 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #656565;
    line-height: 26px;
    padding: 0px 0px 10px 0px;
    text-align: left;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 600px
}

.home-elem-167 {
    padding: 80px 16px 80px 16px;
    flex-direction: column;
    align-items: center;
    background-color: #0e4823ff;
    background-image: url(https://res.cloudinary.com/dbyioi2qq/q_auto/v1687948802/static/group-498png_1687948694_82706.png);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat no-repeat;
    background-attachment: scroll;
    background-clip: border-box;
    background-origin: padding-box;
    margin-top: 70px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 1200px;
    position: relative
}

.home-elem-168 {
    font-family: Fraunces;
    font-size: 36px;
    font-weight: 700;
    color: #ffffffff;
    line-height: 46px;
    padding: 0px 0px 20px 0px;
    text-align: center;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-169 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 400;
    color: #ffffffff;
    line-height: 28px;
    padding: 0px 0px 40px 0px;
    text-align: center;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: 585px
}

.home-elem-172 {
    padding: 10px 10px 10px 10px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    -moz-column-gap: 20px;
    column-gap: 20px;
    background-color: #EFEFEF;
    border-radius: 100px 100px 100px 100px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 500px;
    position: relative
}

.home-elem-170 {
    padding: 8px 16px 8px 25px;
    font-family: Fraunces;
    font-size: 16px;
    color: #000000;
    line-height: 26px;
    background-color: #ffffff00;
    border: 1px solid #cccccc;
    border-radius: 4px 4px 4px 4px;
    border-width: 0px 0px 0px 0px;
    border-style: solid solid solid solid;
    border-color: #E2E2E2 #E2E2E2 #E2E2E2 #E2E2E2;
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    max-width: 300px
}

.home-elem-171 {
    padding: 12px 26px 12px 26px;
    background-color: #000000ff;
    border-radius: 100px 100px 100px 100px;
    border-width: 0px 0px 0px 0px;
    border-style: groove groove groove groove;
    border-color: #333 #333 #333 #333;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 700;
    color: #ffffffff;
    line-height: 26px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative
}

.home-elem-188 {
    padding: 100px 16px 10px 16px;
    flex-direction: column;
    align-items: center;
    background-color: #8a79bd00;
    opacity: 100%;
    margin-left: auto;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: auto;
    max-width: 1980px;
    position: relative
}

.home-elem-185 {
    padding: 0px 0px 20px 0px;
    flex-direction: row;
    justify-content: space-between;
    -moz-column-gap: 50px;
    column-gap: 50px;
    border-width: 0px 0px 1px 0px;
    border-style: none none solid none;
    border-color: transparent transparent #A7A7A7 transparent;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 1200px;
    position: relative
}

.home-elem-176 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 320px;
    position: relative
}

.home-elem-173 {
    font-family: Fraunces;
    font-size: 25px;
    font-weight: 900;
    color: #000000ff;
    line-height: 35px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative;
    max-width: -moz-fit-content;
    max-width: fit-content
}

.home-elem-174 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 400;
    color: #000000ff;
    line-height: 28px;
    padding: 20px 0px 20px 0px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-183 {
    padding: 20px 0px 0px 0px;
    flex-direction: row;
    -moz-column-gap: 14px;
    column-gap: 14px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    position: relative
}

.home-elem-180 {
    padding: 0px 0px 0px 0px;
    color: #000000ff;
    text-align: left;
    font-size: 32px;
    line-height: 42px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-182 {
    padding: 0px 0px 0px 0px;
    color: #000000ff;
    text-align: left;
    font-size: 32px;
    line-height: 42px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-181 {
    padding: 0px 0px 0px 0px;
    color: #000000ff;
    text-align: left;
    font-size: 32px;
    line-height: 42px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.home-elem-197 {
    -o-object-fit: cover;
    object-fit: cover;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    max-width: 600px;
    max-height: 600px;
}

.home-elem-184 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    height: auto;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    margin-right: NaNpx;
    max-width: none;
    position: relative
}

.home-elem-177 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 700;
    color: #000000ff;
    line-height: 26px;
    padding: 0px 0px 20px 0px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-178 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #000000ff;
    line-height: 26px;
    padding: 0px 0px 5px 0px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-179 {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    color: #000000ff;
    line-height: 26px;
    padding: 0px 0px 20px 0px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.home-elem-175 {
    padding: 10px 30px 10px 30px;
    background-color: #0e4823ff;
    border-radius: 100px 100px 100px 100px;
    border-width: 0px 0px 0px 0px;
    border-style: groove groove groove groove;
    border-color: #333 #333 #333 #333;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 600;
    color: #ffffffff;
    line-height: 26px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    min-height: 56px
}

.home-elem-187 {
    padding: 10px 0px 10px 0px;
    flex-direction: row;
    justify-content: center;
    -moz-column-gap: 50px;
    column-gap: 50px;
    row-gap: 20px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 1300px;
    position: relative
}

.home-elem-186 {
    font-family: Fraunces;
    font-size: 15px;
    font-weight: 500;
    color: #000000ff;
    line-height: 25px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.paragraph {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400
}

.video-gallery {
    display: flex;
    justify-content: space-around;
    padding: 20px;
  }
  
  .video-container {
    flex: 1;
    padding: 10px;
  }
  
  video {
    width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  