/* VehicleClaimRegistration.css */
/* .container {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(38, 21, 21, 0.1);
  background-color: white;
} */

/* Radio Button Group */
.radio-group {
  display: flex;
  align-items: center;
}

.radio-group>label {
  margin-right: 15px;
  /* Spacing between each radio option */
  font-weight: normal;
  /* Adjust the font weight as necessary */
}

/* Individual Radio Button Label */
.radio-group label label {
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
  /* Spacing between radio button and its label text */
  cursor: pointer;
  /* Indicates the label is clickable */
}

/* Radio Buttons */
.radio-group input[type="radio"] {
  margin-right: 5px;
  /* Space before the label text */
}

.label,
/* For standalone labels */
.form-field label {
  /* For labels inside .form-field */
  display: block;
  margin-bottom: 10px;
  color: #333;
}

.form-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.form-field {
  font-size: small;
  flex: 1 1 calc(25% - 10px);
  /* Adjust for 4 items per row, accounting for gap */
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  /* Space between rows */
}

.inputField,
/* For input elements */
.textAreaField {
  /* For textarea elements, if you decide to use this class */
  width: 100%;
  padding: 7px;
  margin-top: 5px;
  border: 1px solid #ccc;
  background-color: #100f0f08;
  border-radius: 4px;
  /* font-size: medium; */
}

.button {
  /* For button elements */
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.button:hover {
  background-color: #45a049;
}

.required {
  /* For indicating required fields */
  color: red;
  display: inline;
  /* To show asterisk alongside the label */
  margin-left: 5px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .form-row {
    /* flex-direction: column; */

  }

  .form-field {
    flex: 1 1 100%;
    /* Each field takes full width on smaller screens */
  }

  .button {
    width: 100%;
    /* Makes the button full width on smaller screens */
  }
}

.heading-box {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 140%;
  color: red;
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 250px;
}

/* Combined styles from both CSS snippets */

.form-field.radio-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.radio-group label {
  margin-right: 5px;
  /* Spacing to the right of each label */
  white-space: nowrap;
  /* Prevents label text from wrapping */
}

.radio-group input[type="radio"] {
  margin-right: 5px;
  /* Spacing to the right of each radio button */
}

/* Styles for text, date, and file inputs */

input[type="text"],
input[type="date"],
input[type="file"] {
  display: block;
  margin-top: 5px;
}

input[type="radio"] {
  margin-left: 10px;
}

.notUploaded {
  color: red;
  font-style: italic;
  font-size: 14px;
  margin: 10px 0;
  text-align: center;
}

.notUploaded1 {
  padding: 5px;     /* reduced from 10px to 5px for smaller internal padding */
  font-size: 14px;  /* adding font-size for smaller text, adjust as needed */
  font-weight: bold;
  color: #333;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(134, 127, 127, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px); /* Blurs the background */
}

.modal-content {
  background: #0000004a;
  border-radius: 10px;
  box-shadow: 18px 20px 0px rgba(0, 0, 0, 0.1);
  cursor: auto;
}

.modal-content img {
  max-height: 70vh;
  max-width: 95vw;
}


.task-details {
  background: #e8ebff54;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.task-details:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
