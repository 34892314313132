.responsive-table {
  overflow-x: auto; /* Allows table to scroll horizontally */
  width: 100%; /* Ensures that the container fits the width of its parent */
margin-bottom: 20px;
}

@media (max-width: 768px) {
  .responsive-table table {
    width: auto; 
  }
  
  .responsive-table th, .responsive-table td {
    padding: 8px; /* Reduces padding on smaller screens */
  }

  /* .responsive-table th:nth-child(1), .responsive-table td:nth-child(1) {
    display: none;
  } */
  .responsive-table th:nth-child(3), .responsive-table td:nth-child(3) {
    display: none;
  }
  /* .responsive-table th:nth-child(4), .responsive-table td:nth-child(4) {
    display: none;
  } */
  /* .responsive-table th:nth-child(6), .responsive-table td:nth-child(6) {
    display: none;
  } */
}

/* Additional Media Queries for other specific tables if needed */
@media (max-width: 768px) {

  .responsive-table {
    width: 100%; /* Ensures that the container fits the width of its parent */
  }
  .vendor-response-responsive-table table {
    width: auto; /* Ensures the table can expand beyond the screen width */
  }
  
  .vendor-response-responsive-table th, .vendor-response-responsive-table td {
    padding: 8px; /* Reduces padding on smaller screens */
  }
}

@media (max-width: 600px) {

  .responsive-table {
    width: 95%; /* Ensures that the container fits the width of its parent */
  }
  .vendor-response-responsive-table table {
    width: auto; /* Ensures the table can expand beyond the screen width */
  }
  
  .vendor-response-responsive-table th, .vendor-response-responsive-table td {
    padding: 8px; /* Reduces padding on smaller screens */
  }
}

@media (max-width: 530px) {

  .responsive-table {
    width: 100%; /* Ensures that the container fits the width of its parent */
  }
  .vendor-response-responsive-table table {
    width: auto; /* Ensures the table can expand beyond the screen width */
  }
  
  .vendor-response-responsive-table th, .vendor-response-responsive-table td {
    padding: 8px; /* Reduces padding on smaller screens */
  }
}

@media (max-width: 500px) {

  .responsive-table {
    width: 100%; /* Ensures that the container fits the width of its parent */
  }
  .vendor-response-responsive-table table {
    width: auto; /* Ensures the table can expand beyond the screen width */
  }
  
  .vendor-response-responsive-table th, .vendor-response-responsive-table td {
    padding: 6px; /* Reduces padding on smaller screens */
  }
}

@media (max-width: 450px) {

  .responsive-table {
    width: 100%; /* Ensures that the container fits the width of its parent */
  }
  .vendor-response-responsive-table table {
    width: auto; /* Ensures the table can expand beyond the screen width */
  }
  
  .vendor-response-responsive-table th, .vendor-response-responsive-table td {
    padding: 6px; /* Reduces padding on smaller screens */
  }
}

@media (max-width: 425px) {

  .responsive-table {
    width: 100%; /* Ensures that the container fits the width of its parent */
  }
  .vendor-response-responsive-table table {
    width: auto; /* Ensures the table can expand beyond the screen width */
  }
  
  .vendor-response-responsive-table th, .vendor-response-responsive-table td {
    padding: 6px; /* Reduces padding on smaller screens */
  }
}

@media (max-width: 400px) {

  .responsive-table {
    width: 100%; /* Ensures that the container fits the width of its parent */
  }
  .vendor-response-responsive-table table {
    width: auto; /* Ensures the table can expand beyond the screen width */
  }
  
  .vendor-response-responsive-table th, .vendor-response-responsive-table td {
    padding: 5px; /* Reduces padding on smaller screens */
  }
}
@media (max-width: 385px) {

  .responsive-table {
    width: 100%; /* Ensures that the container fits the width of its parent */
  }
  .vendor-response-responsive-table table {
    width: auto; /* Ensures the table can expand beyond the screen width */
  }
  
  .vendor-response-responsive-table th, .vendor-response-responsive-table td {
    padding: 5px; /* Reduces padding on smaller screens */
  }
}

@media (max-width: 375px) {

  .responsive-table {
    width: 100%; /* Ensures that the container fits the width of its parent */
  }
  .vendor-response-responsive-table table {
    width: auto; /* Ensures the table can expand beyond the screen width */
  }
  
  .vendor-response-responsive-table th, .vendor-response-responsive-table td {
    padding: 5px; /* Reduces padding on smaller screens */
  }
}
@media (max-width: 360px) {

  .responsive-table {
    width: 100%; /* Ensures that the container fits the width of its parent */
  }
  .vendor-response-responsive-table table {
    width: auto; /* Ensures the table can expand beyond the screen width */
  }
  
  .vendor-response-responsive-table th, .vendor-response-responsive-table td {
    padding: 5px; /* Reduces padding on smaller screens */
  }
}

@media (max-width: 338px) {

  .responsive-table {
    width: 100%; /* Ensures that the container fits the width of its parent */
    font-size: 9px;
  }
  .vendor-response-responsive-table table {
    width: auto; /* Ensures the table can expand beyond the screen width */
  }
  
  .vendor-response-responsive-table th, .vendor-response-responsive-table td {
    padding: 5px; /* Reduces padding on smaller screens */
  }
}


@media (max-width: 768px) {
  .register-responsive-table table {
    width: auto; /* Ensures the table can expand beyond the screen width */
  }
  
  .register-responsive-table th, .register-responsive-table td {
    padding: 8px; /* Reduces padding on smaller screens */
  }
  /* .register-responsive-table th:nth-child(2), .register-responsive-table td:nth-child(2) {
    display: none;
  } */
  .register-responsive-table th:nth-child(3), .register-responsive-table td:nth-child(3) {
    display: none;
  }
  .register-responsive-table th:nth-child(4), .register-responsive-table td:nth-child(4) {
    display: none;
  }
  .register-responsive-table th:nth-child(5), .register-responsive-table td:nth-child(5) {
    display: none;
  }
}


.bigtitle {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-left: 30px;
  /* text-align: center; */
}
.dashboardTitle {
  font-size: 25px;
  font-weight: bold;
  /* margin-top: 30px; */
  margin-left: 60px;
  /* text-align: center; */
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  background-color: #ddd;
  cursor: pointer;
  box-shadow: none; /* Ensure box shadow is set to none */
}

.pagination button:focus,
.pagination button:hover {
  box-shadow: none;
}

.MuiButtonGroup-root .MuiButton-root {
  box-shadow: none !important;
}


.pagination button.active {
  background-color: #007bff;
  color: rgb(0, 0, 0);
}

.pagination button:hover {
  background-color: #007bff;
  color: rgb(0, 0, 0);
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {

  .pagination {
    justify-content: center;
    margin-left: 10px;
    box-shadow: none;
  }

  .pagination button {
    padding: 5px 8px;
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .pagination {
    justify-content: left;
    box-shadow: none;
  }

  .pagination button {
    /* padding: 5px 10px; */
    font-size: 0.75rem;
  }
}


/* Add these styles to your CSS file */

.header-container-search {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.header-container-search h3 {
  flex: 1 1 auto;
  margin: 0;
}

.search-field {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.search-field input {
  width: 100%;
  max-width: 250px;
}

@media (max-width: 768px) {
  .header-container-search {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-field {
    width: 100%;
    margin-top: 10px;
    margin-left : 5px;
  }

  .search-field input {
    max-width: 100%;
  }
}











table {
  max-width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table,
th,
td {
  border-bottom: 1px solid #ddd;
}

th,
td {
  text-align: center;
  padding: 8px;
  font-size: 13px;

}

th {
  background:rgb(169 187 169);
  color: white;
  text-align: center;

}

tr:nth-child(even) {
  background-color: #f2f2f2;
  text-align: center;
}

tr:hover {
  background-color: #ddd;
  text-align: center;
}

.view-button {
  padding: 6px 16px;
  background-color: lightblue;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
}
.deactivate-button {
  padding: 6px 16px;
  background-color: rgb(234, 43, 43);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.form-field {
  flex: 1 1 22%;
  /* Adjust as needed to fit 4 items in a row */
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  /* Space between rows */
}



/* FormStyles.css */
/* Base styling for the form */
.form-container {
  display: flex;
  flex-direction: column; /* Makes elements stack vertically */
  padding: 20px;
  max-width: 600px; /* Set a max width for the form */
  margin: auto; /* Center the form in the page */
}

.form-row {
  display: flex;
  flex-wrap: wrap; /* Allows form fields to wrap onto the next line */
  gap: 20px; /* Adds space between form fields */
}

.form-field {
  flex: 1 1 200px; /* Each field tries to fill space but not less than 200px */
  display: flex;
  flex-direction: column; /* Makes label and input stack vertically */
}

.form-field label {
  margin-bottom: 5px; /* Adds a small space below each label */
}

input[type='text'],
input[type='date'] {
  width: 100%; /* Full width of its parent */
  padding: 8px;
  box-sizing: border-box; /* Includes padding in width calculation */
}

/* Media Queries for Responsive Adjustments */
@media (max-width: 768px) {
  .form-field {
    flex: 1 1 100%; /* Each field takes full width on smaller screens */
  }
}




.form-field input[type='text'],
.form-field input[type='date'],
.form-field input[type='number'],
.form-field textarea {
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
  /* border-radius: 4px; */
}

.form-field input[type='text']:focus,
.form-field input[type='date']:focus 
.form-field input[type='number']:focus{
  border-color: #0056b3;
  outline: none;
}

.vendor-table {
  width: '100%';
  border-collapse: 'collapse';
  margin-bottom: '20px';
  border: '1px solid black';
}

.vendor-table th,
.vendor-table td {
  border: '1px solid black';
  padding: '5px';
}

.vendor-table th {
  border-bottom: '2px solid black';
}

.title1 {
  /* text-align: center; */
  /* background: linear-gradient(to right, rgb(143, 187, 143) 0%,rgb(193, 237, 193) 100%); */
  padding: 10px;
  /* color: white; */
  /* margin-left: 60px; */
  /* margin-top: 50px; */
  width: 80%;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); */
  font-size: 1em;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  user-select: none;
  cursor: default;
}

.allvendor-response {
  width: 100%;
  border-collapse: collapse;
  margin-bottom:90px;
  margin-left: 40px;

}
@media (max-width: 768px) {
  .allvendor-response {
    width: 100%;
    border-collapse: collapse;
    margin-bottom:90px;
    margin-left: 0px;
  }
}



.form-field {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.generate-button {
  margin-top: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: lightblue;
  color: white;
  width: 100%;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.generate-button:hover {
  background-color: #5fbae9;
}

.download-link {
  margin-top: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: lightblue;
  color: white;
  width: 100%;
  max-width: 200px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.download-link:hover {
  background-color: #5fbae9;
}

.header-container-search {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
}

.header-container-search h3 {
  flex: 1 1 100%;
  margin: 5px 0;
}

.form-field {
  flex: 1 1 calc(50% - 10px);
  margin: 5px;
}

.search-field input {
  width: 100%;
}

.generate-button,
.download-link {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 10px;
  background-color: transparent;
  color: black;
  border: 5px solid #96a3e4;
  cursor: pointer;
  border-radius: 10px;
  text-decoration: none;
}

.generate-button:hover,
.download-link:hover {
  background-color: #45a049;
}

@media (max-width: 600px) {
  .header-container-search {
    flex-direction: column;
    align-items: stretch;
  }

  .form-field {
    flex: 1 1 100%;
    margin: 5px 0;
  }

  .generate-button,
  .download-link {
    width: 100%;
  }

  .search-field input {
    width: 40px;
  }
}
.view-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
}

label {
  display: flex;
  flex-direction: column;
  flex: 1 1 45%;
  margin: 10px;
}

input[type="text"],
input[type="number"] {
  padding: 10px;
  font-size: 1rem;
  margin-top: 5px;
}

.bigtitle {
  font-size: 20px;
  margin-bottom: 20px;
  /* text-align: center; */
}

.form-search {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:10px
}

.label-class {
  flex: 1;
  min-width: 200px;
}

@media (max-width: 768px) {
  .form-search {
    flex-direction: column;
  }

  .label-class {
    flex: 1 1 100%;
    width: 200px;
    padding-left: 10px;
  }

  .bigtitle {
    font-size: 1.25rem;
  }

  input[type="text"],
  input[type="number"] {
    font-size: 0.8rem;
    padding: 3px;
  }
}

.visitor-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px;
}

.add-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #796de5;
  color: white;
}

@media (max-width: 480px) {
  .visitor-container {
    flex-direction: column;
    align-items: flex-start;
    /* width: 310px; */
  }

  .visitor-container .add-button {
    width: 50%;
    margin-top: 10px;
  }
}

@media (max-width: 350px) {
  .visitor-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .visitor-container .add-button {
    width: 40%;
    margin-top: 10px;
  }}
