
  .btn-grey{
      background-color:#D8D8D8;
      color:#FFF;
  }
  .rating-block{
      background-color:#FAFAFA;
      border:1px solid #EFEFEF;
      padding:15px 15px 20px 15px;
      border-radius:3px;
  }
  .bold{
      font-weight:700;
  }
  .padding-bottom-7{
      padding-bottom:7px;
  }
  
  .review-block{
      background-color:#FAFAFA;
      border:1px solid #EFEFEF;
      padding:15px;
      border-radius:3px;
      margin-bottom:15px;
  }
  .review-block-name{
      font-size:12px;
      margin:10px 0;
  }
  .review-block-date{
      font-size:12px;
  }
  .review-block-rate{
      font-size:13px;
      margin-bottom:15px;
  }
  .review-block-title{
      font-size:15px;
      font-weight:700;
      margin-bottom:10px;
  }
  .review-block-description{
      font-size:13px;
  }