@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Poppins', sans-serif; */
}
html,body{
  display: grid;
  height: 100%;
  width: 80%;
  /* place-items: center; */
  background: #f2f2f2;
  /* background: linear-gradient(-135deg, #c44fbd, #4158d0); */
}
::selection{
  background: #4158d0;
  color: #fff;
}
.wrapper {
  max-width: 380px;
  width: 100%;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 15px 20px rgba(0,0,0,0.1);
  margin: 100px auto;
  padding-bottom: 50px;
}

@media (max-width: 500px) {
  .wrapper {
    margin: 50px 20px;
    padding-bottom: 30px;
  }
}


.wrapper .title {
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  line-height: 100px; /* Might want to adjust this for smaller screens */
  color: #fff;
  user-select: none;
  border-radius: 15px 15px 0 0;
  background: linear-gradient(-135deg, #7bce67, #131313);
}

@media (max-width: 500px) {
  .wrapper .title {
    font-size: 28px;
    line-height: 80px;
  }
}

.wrapper form {
  padding: 10px 20px 40px 20px;
}

@media (max-width: 500px) {
  .wrapper form {
    padding: 10px 15px 30px 15px;
  }
}


.wrapper form .field{
  height: 50px;
  width: 100%;
  margin-top: 20px;
  position: relative;
}
.wrapper form .field input {
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 17px;
  padding-left: 20px;
  border: 1px solid lightgrey;
  border-radius: 25px;
  transition: all 0.3s ease;
}

@media (max-width: 500px) {
  .wrapper form .field input {
    padding-left: 15px;
    font-size: 15px;
  }
  .wrapper form .field label {
    font-size: 15px;
    left: 15px;
  }
}

/* .wrapper form .field input:focus,
form .field input:valid{
  border-color: #4158d0;
} */
.wrapper form .field label{
  position: absolute;
  top: 50%;
  left: 20px;
  color: #999999;
  font-weight: 400;
  font-size: 17px;
  pointer-events: none;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}
form .field input:focus ~ label,
form .field input:valid ~ label{
  top: 0%;
  font-size: 16px;
  color: #4158d0;
  background: #fff;
  transform: translateY(-50%);
}
form .content{
  display: flex;
  width: 100%;
  height: 50px;
  font-size: 16px;
  align-items: center;
  justify-content: space-around;
}
form .content .checkbox{
  display: flex;
  align-items: center;
  justify-content: center;
}
form .content input{
  width: 15px;
  height: 15px;
  background: red;
}
form .content label{
  color: #262626;
  user-select: none;
  padding-left: 5px;
}
form .content .pass-link{
  color: "";
}
form .field input[type="submit"]{
  color: white;
  border: none;
  padding-left: 0;
  margin-top: -10px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  background: #0e4823ff;
  transition: all 0.3s ease;
  margin-top: 30px;
}
form .field input[type="submit"]:active{
  transform: scale(0.95);
}
form .signup-link{
  color: #262626;
  margin-top: 20px;
  text-align: center;
}
form .pass-link a,
form .signup-link a{
  color: #4158d0;
  text-decoration: none;
}
form .pass-link a:hover,
form .signup-link a:hover{
  text-decoration: underline;
}
.image-section {
  display: none; /* Hide by default, assuming mobile-first approach */
}

/* This media query applies to devices with a width of 768 pixels or more */
@media (min-width: 768px) {
  .image-section {
    display: block; /* Show the image section on laptop/desktop screens */
  }
}


@keyframes slideIn {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn 1s ease-out forwards;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); */
}



.selecting-container {
  display: flex;
  justify-content: center;
}

.selecting-box {
  padding: 10px;
  font-size: 13px; /* Font size */
  font-weight: bold; /* Font weight */
  text-align: center; /* Center the text */
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease; /* Animation */
  width: 100px; /* Fixed width for consistent sizing */
  cursor: pointer; /* Pointer cursor on hover */
}

.selecting-box:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Shadow on hover */
}

.vendorselected {
  border: 1px solid blue;
  color: black;
  background-color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.customerselected {
  border: 1px solid rgb(231, 201, 12);
  color: black;
  background-color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.vendorselected.selected {
  background-color: rgb(247, 240, 12);
  color: black;
  font-weight: bold;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4), inset 0 0 10px rgba(255, 255, 255, 0.2);
}

.customerselected.selected {
  background-color: rgb(247, 240, 12);
  color: black;
  font-weight: bold;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4), inset 0 0 10px rgba(255, 255, 255, 0.2);
}
