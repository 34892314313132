undefined * {
    line-height: 1
}

*,
*::before,
*::after {
    box-sizing: border-box
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-weight: 300
}

a {
    color: inherit;
    text-decoration: inherit
}

.left {
    text-align: left
}

.right {
    text-align: right
}

.center {
    text-align: center;
    margin-left: auto;
    margin-right: auto
}

.justify {
    text-align: justify
}

/* .container {
    max-width: 1140px;
    width: 100% !important;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto !important;
    margin-left: auto !important
}

@media (min-width:576px) and (max-width:768px) {
    .container {
        max-width: 540px
    }
}

@media (min-width:768px) and (max-width:992px) {
    .container {
        max-width: 720px
    }
}

@media (min-width:992px) and (max-width:1200px) {
    .container {
        max-width: 960px
    }
} */

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    line-height: 1
}

body {
    font-size: 0;
    font-family: "Nunito Sans"
}

html {
    font-size: 100%
}

input,
textarea,
select {
    min-height: 20px;
    border: 0;
    font-size: 16px
}

.headerOne,
.headerTwo,
.headerThree,
.headerFour,
.headerFive,
.headerSix,
.paragraph {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 0.1rem 0;
    width: 100%
}

.lg-text-center-vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: inherit
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

body {
    line-height: 1
}

ol,
ul {
    list-style: none
}

blockquote,
q {
    quotes: none
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
    color: inherit;
    text-decoration: none;
    cursor: pointer
}

img {
    width: 100%;
    height: 100%;
    -o-object-fit: inherit;
       object-fit: inherit;
    border-radius: inherit
}

span {
    display: block
}

.location1-elem-53:hover {
    color: #000000
}

.location1-elem-60:hover {
    color: #000000
}

.location1-elem-5 {
    padding: 20px 16px 20px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0e4823ff;
    z-index: 1200;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: auto;
    min-height: 20px;
    min-width: 20px;
    position: relative;
    display: flex;
    max-width: 1980px;
    margin-right: auto
}

.location1-elem-4 {
    justify-content: space-between;
    align-items: center;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    max-width: 1200px;
    position: relative
}

.notification {
    position: relative;
    top: 20px;
    
    background: #ff4d4d;
    color: white;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    min-width: 250px;
    font-size: 16px;
    font-weight: bold;
    transition: right 0.5s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}

.notification p {
    margin: 0;
    flex: 1;
}

.notification.show {
    right: 20px; /* Slide into view */
}

.close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    margin-left: 10px;
}


.location1-elem-3 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #00000000;
    background-image: url(https://res.cloudinary.com/dbyioi2qq/q_auto/v1688108670/static/component-126-2svg_1688108561_26947.svg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat no-repeat;
    background-attachment: scroll;
    background-clip: border-box;
    background-origin: padding-box;
    border-width: 0px 0px 0px 0px;
    border-style: solid solid solid solid;
    border-color: #000000 #000000 #000000 #000000;
    height: 15px;
    width: 35px;
    display: none;
    position: relative
}

.location1-elem-2 {
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    position: relative
}

.location1-elem-1 {
    font-family: Fraunces;
    font-size: 25px;
    font-weight: 700;
    color: #ffffffff;
    line-height: 35px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-9 {
    padding: 50px 16px 50px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
    background-color: #E9F3FF;
    z-index: 1;
    margin-left: auto;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    max-width: 1440px;
    margin-right: auto;
    max-height: none;
    position: relative
}

.location1-elem-8 {
    padding: 50px 100px 50px 100px;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    -moz-column-gap: 100px;
         column-gap: 100px;
    row-gap: 15px;
    background-color: #ffffffff;
    z-index: 1;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    max-width: 1150px;
    position: relative
}

.location1-elem-10 {
    padding: 10px 10px 10px 10px;
    flex-direction: column;
    row-gap: 50px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 550px;
    position: relative
}

.location1-elem-6 {
    font-family: Inter;
    font-size: 40px;
    font-weight: 700;
    color: #182433;
    line-height: 50px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-7 {
    padding: 15px 24px 15px 24px;
    background-color: #182433;
    border-radius: 0px 0px 0px 0px;
    border-width: 0px 0px 0px 0px;
    border-style: groove groove groove groove;
    border-color: #333 #333 #333 #333;
    font-family: Oxygen;
    font-size: 20px;
    font-weight: 600;
    color: #ffffffff;
    line-height: 30px;
    z-index: 1;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    max-width: 230px;
}

.location1-elem-11 {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 260px;
    position: relative;
    max-width: 300px
}

.location1-elem-16 {
    padding: 50px 16px 50px 16px;
    flex-direction: column;
    align-items: center;
    background-color: #E9F3FF;
    margin-left: auto;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    margin-right: auto;
    max-width: 1980px;
    position: relative
}

.location1-elem-12 {
    font-family: Inter;
    font-size: 50px;
    font-weight: 700;
    color: #182433;
    line-height: 60px;
    padding: 0px 0px 100px 0px;
    text-align: center;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-15 {
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 1162px;
    position: relative
}

.location1-elem-14 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location1-elem-17 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location1-elem-13 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-50 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location1-elem-49 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location1-elem-48 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-47 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location1-elem-46 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location1-elem-45 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-44 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location1-elem-43 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location1-elem-42 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-41 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location1-elem-40 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location1-elem-39 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-38 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location1-elem-37 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location1-elem-36 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-35 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location1-elem-34 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location1-elem-33 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-32 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location1-elem-31 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location1-elem-30 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-29 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location1-elem-28 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location1-elem-27 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-26 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location1-elem-25 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location1-elem-24 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-23 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location1-elem-22 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location1-elem-21 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-20 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location1-elem-19 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location1-elem-18 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-71 {
    padding: 15px 24px 15px 24px;
    background-color: #182433;
    border-radius: 0px 0px 0px 0px;
    border-width: 0px 0px 0px 0px;
    border-style: groove groove groove groove;
    border-color: #333 #333 #333 #333;
    font-family: Oxygen;
    font-size: 20px;
    font-weight: 600;
    color: #ffffffff;
    line-height: 30px;
    z-index: 1;
    margin-left: 608.4507042253522px;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    max-width: 230px
}

.location1-elem-70 {
    padding: 16px 0px 16px 0px;
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-top: 38px;
    display: flex;
    margin-right: auto;
    position: relative
}

.location1-elem-51 {
    -o-object-fit: cover;
       object-fit: cover;
    width: -moz-fit-content;
    width: fit-content;
    height: 152px;
    position: relative
}

.location1-elem-69 {
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 20px;
    min-width: 20px;
    display: flex;
    position: relative
}

.location1-elem-58 {
    padding: 0px 32px 0px 32px;
    justify-content: center;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 20px;
    min-width: 20px;
    display: flex;
    position: relative
}

.location1-elem-57 {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 4px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 20px;
    min-width: 20px;
    display: flex;
    position: relative
}

.location1-elem-52 {
    font-family: Nunito Sans;
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    line-height: 28px;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-53 {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 300;
    color: #333;
    line-height: 24px;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-54 {
    align-items: stretch;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 300;
    color: #333;
    line-height: 24px;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-55 {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 300;
    color: #333;
    line-height: 24px;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-56 {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 300;
    color: #333;
    line-height: 24px;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-68 {
    padding: 0px 32px 0px 32px;
    flex-direction: column;
    justify-content: space-between;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    min-height: 20px;
    min-width: 20px;
    display: flex;
    position: relative
}

.location1-elem-62 {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 4px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 20px;
    min-width: 20px;
    display: flex;
    position: relative
}

.location1-elem-59 {
    font-family: Nunito Sans;
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    line-height: 28px;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-60 {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 300;
    color: #333;
    line-height: 24px;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-61 {
    align-items: stretch;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 300;
    color: #333;
    line-height: 24px;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location1-elem-67 {
    -moz-column-gap: 12px;
         column-gap: 12px;
    row-gap: 4px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 20px;
    min-width: 20px;
    display: flex;
    position: relative
}

.location1-elem-63 {
    -o-object-fit: contain;
       object-fit: contain;
    width: 22px;
    height: 22px;
    position: relative
}

.location1-elem-64 {
    padding: 2px 2px 2px 2px;
    -o-object-fit: contain;
       object-fit: contain;
    width: 22px;
    height: 22px;
    position: relative
}

.location1-elem-65 {
    -o-object-fit: contain;
       object-fit: contain;
    width: 22px;
    height: 22px;
    position: relative
}

.location1-elem-66 {
    -o-object-fit: contain;
       object-fit: contain;
    width: 22px;
    height: 22px;
    position: relative
}

.paragraph {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400
}
/* This CSS goes into your Location1.css file */

.photo-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Adjust the width as necessary */
}

.photo-inputs-row {
    display: flex;
    justify-content: space-around; /* Distributes space evenly around the items */
    flex-wrap: wrap; /* Allows items to wrap onto the next line if needed */
    margin-top: 20px;
    width: 100%; /* Ensures the container takes full width */
}

.photo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.photo-wrapper img {
    width: 100px; /* Fixed width for all images */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 5px;
}

.view-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 18px;
    color: white;
    background-color: #4CAF50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.inputField, /* For input elements */
.textAreaField { /* For textarea elements, if you decide to use this class */
  width: 100%;
  padding: 7px;
  margin-top: 5px;
  border: 1px solid #ccc;
  background-color: lightgrey;
  border-radius: 4px;
  font-size: small;
}
.form-field {
    flex: 1 1 22%; /* Adjust as needed to fit 4 items in a row */
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; /* Space between rows */
  }
  .form-field {
    flex: 1 1 100%; /* Full width on small screens */
  }
