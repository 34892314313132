undefined * {
    line-height: 1
}

*,
*::before,
*::after {
    box-sizing: border-box
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-weight: 300
}

a {
    color: inherit;
    text-decoration: inherit
}

.left {
    text-align: left
}

.right {
    text-align: right
}

.center {
    text-align: center;
    margin-left: auto;
    margin-right: auto
}

.justify {
    text-align: justify
}

.container .container-for-tables {
    /* max-width: 1540px; */
    width: 100% !important;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto !important;
    margin-left: auto !important
}
@media (min-width:250px) and (max-width:319px) {
    .container-for-tables {
        max-width: 245px
    }
}
@media (min-width:320px) and (max-width:339px) {
    .container-for-tables {
        max-width: 290px
    }
}
@media (min-width:340px) and (max-width:375px) {
    .container-for-tables {
        max-width: 350px
    }
}
@media (min-width:375px) and (max-width:429px) {
    .container-for-tables {
        max-width: 380px
    }
}
@media (min-width:430px) and (max-width:575px) {
    .container-for-tables {
        max-width: 420px
    }
}
@media (min-width:576px) and (max-width:768px) {
    .container-for-tables {
        max-width: 540px
    }
}
@media (min-width:768px) and (max-width:992px) {
    .container-for-tables {
        max-width: 720px
    }
}
@media (min-width:992px) and (max-width:1100px) {
    .container-for-tables {
        max-width: 960px
    }
}
@media (min-width:1101px) and (max-width:1200px) {
    .container-for-tables {
        max-width: 1150px
    }
}
@media (min-width:1201px) and (max-width:1300px) {
    .container-for-tables {
        max-width: 1260px
    }
}

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    line-height: 1
}

body {
    font-size: 0;
    font-family: "Nunito Sans"
}

html {
    font-size: 100%
}

input,
textarea,
select {
    min-height: 20px;
    border: 0;
    font-size: 16px
}

.headerOne,
.headerTwo,
.headerThree,
.headerFour,
.headerFive,
.headerSix,
.paragraph {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 0.1rem 0;
    width: 100%
}

.lg-text-center-vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: inherit
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-family: sans-serif;
    vertical-align: baseline,
    /* font-family:'sans-serif', */
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

body {
    line-height: 1
}

ol,
ul {
    list-style: none
}

blockquote,
q {
    quotes: none
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
    color: inherit;
    text-decoration: none;
    cursor: pointer
}

img {
    width: 100%;
    height: 100%;
    -o-object-fit: inherit;
       object-fit: inherit;
    border-radius: inherit
}

span {
    display: block
}

.location2-elem-55::-moz-placeholder {
    font-family: Kumbh Sans;
    color: #0000007f
}

.location2-elem-55::placeholder {
    font-family: Kumbh Sans;
    color: #0000007f
}

.location2-elem-59::-moz-placeholder {
    font-family: Kumbh Sans;
    color: #0000007f
}

.location2-elem-59::placeholder {
    font-family: Kumbh Sans;
    color: #0000007f
}

.location2-elem-52::-moz-placeholder {
    font-family: Roboto;
    color: #0000007f
}

.location2-elem-52::placeholder {
    font-family: Roboto;
    color: #0000007f
}

.location2-elem-5 {
    padding: 20px 16px 20px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0e4823ff;
    z-index: 1200;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: auto;
    min-height: 20px;
    min-width: 20px;
    position: relative;
    display: flex;
    max-width: 1980px;
    margin-right: auto
}

.location2-elem-4 {
    justify-content: space-between;
    align-items: center;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    max-width: 1200px;
    position: relative
}

.location2-elem-3 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #00000000;
    background-image: url(https://res.cloudinary.com/dbyioi2qq/q_auto/v1688108670/static/component-126-2svg_1688108561_26947.svg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat no-repeat;
    background-attachment: scroll;
    background-clip: border-box;
    background-origin: padding-box;
    border-width: 0px 0px 0px 0px;
    border-style: solid solid solid solid;
    border-color: #000000 #000000 #000000 #000000;
    height: 15px;
    width: 35px;
    display: none;
    position: relative
}

.location2-elem-2 {
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    position: relative
}

.location2-elem-1 {
    font-family: Fraunces;
    font-size: 25px;
    font-weight: 700;
    color: #ffffffff;
    line-height: 35px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-65 {
    padding: 50px 16px 50px 16px;
    flex-direction: column;
    align-items: stretch;
    z-index: 1;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: auto;
    min-height: 100vh;
    min-width: 20px;
    position: relative;
    display: flex;
    max-width: 1200px;
    margin-right: auto
}

.location2-elem-64 {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    z-index: 1;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 20px;
    min-width: 20px;
    position: relative;
    display: flex;
    max-width: 700px
}

.location2-elem-63 {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: auto;
    min-height: 20px;
    min-width: 20px;
    position: relative;
    display: flex;
    max-width: 600px;
    margin-bottom: auto
}

.location2-elem-50 {
    row-gap: 40px;
    font-family: Roboto;
    font-size: 48px;
    font-weight: 700;
    color: #000000;
    line-height: 58px;
    padding: 10px 0px 5px 0px;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-62 {
    padding: 50px 0px 0px 0px;
    flex-direction: column;
    row-gap: 20px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 560px;
    position: relative
}

.location2-elem-57 {
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    -moz-column-gap: 20px;
         column-gap: 20px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    position: relative
}

.location2-elem-56 {
    flex-direction: column;
    row-gap: 8px;
    z-index: 150;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 20px;
    min-width: 20px;
    position: relative;
    display: flex
}

.location2-elem-54 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    color: #000000ff;
    line-height: 26px;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-55 {
    padding: 12px 16px 12px 12px;
    font-family: Roboto;
    font-size: 16px;
    color: #000000;
    line-height: 26px;
    border: 1px solid #cccccc;
    border-radius: 8px 8px 8px 8px;
    z-index: 1;
    width: 100%;
    height: auto;
    display: block;
    position: relative
}

.location2-elem-61 {
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    -moz-column-gap: 20px;
         column-gap: 20px;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    position: relative
}

.location2-elem-60 {
    flex-direction: column;
    row-gap: 8px;
    z-index: 150;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 20px;
    min-width: 20px;
    position: relative;
    display: flex
}

.location2-elem-58 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    color: #000000ff;
    line-height: 26px;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-59 {
    padding: 12px 16px 12px 12px;
    font-family: Roboto;
    font-size: 16px;
    color: #000000;
    line-height: 26px;
    border: 1px solid #cccccc;
    border-radius: 8px 8px 8px 8px;
    z-index: 1;
    width: 100%;
    height: auto;
    display: block;
    position: relative
}

.location2-elem-53 {
    flex-direction: column;
    row-gap: 8px;
    z-index: 15050;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 20px;
    min-width: 20px;
    position: relative;
    display: flex
}

.location2-elem-51 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    color: #000000ff;
    line-height: 26px;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-52 {
    padding: 12px 16px 12px 16px;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;
    color: #000000ff;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 8px 8px 8px 8px;
    z-index: 15050;
    height: 100px;
    width: 100%;
    display: block;
    position: relative
}

.location2-elem-48 {
    padding: 20px 16px 36px 0px;
    justify-content: flex-start;
    align-items: center;
    -moz-column-gap: 10px;
         column-gap: 10px;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 20px;
    min-width: 20px;
    display: flex;
    position: relative
}

.location2-elem-46 {
    padding: 8px 16px 8px 12px;
    font-family: Nunito Sans;
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 8px 8px 8px 8px;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-width: 20px;
    min-height: 20px;
    position: relative
}

.location2-elem-47 {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    color: #0000007f;
    line-height: 14px;
    z-index: 150;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-49 {
    padding: 12px 30px 12px 30px;
    background-color: #000000ff;
    border-radius: 19px 19px 19px 19px;
    border-width: 0px 0px 0px 0px;
    border-style: groove groove groove groove;
    border-color: #333 #333 #333 #333;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    color: #ffffffff;
    line-height: 26px;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative
}

.location2-elem-10 {
    padding: 50px 16px 50px 16px;
    flex-direction: column;
    align-items: center;
    background-color: #E9F3FF;
    margin-left: auto;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    margin-right: auto;
    max-width: 1980px;
    position: relative
}

.location2-elem-6 {
    font-family: Inter;
    font-size: 50px;
    font-weight: 700;
    color: #182433;
    line-height: 60px;
    padding: 0px 0px 100px 0px;
    text-align: center;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-9 {
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 1162px;
    position: relative
}

.location2-elem-8 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location2-elem-11 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location2-elem-7 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-44 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location2-elem-43 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location2-elem-42 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-41 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location2-elem-40 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location2-elem-39 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-38 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location2-elem-37 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location2-elem-36 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-35 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location2-elem-34 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location2-elem-33 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-32 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location2-elem-31 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location2-elem-30 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-29 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location2-elem-28 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location2-elem-27 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-26 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location2-elem-25 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location2-elem-24 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-23 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location2-elem-22 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location2-elem-21 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-20 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location2-elem-19 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location2-elem-18 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-17 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location2-elem-16 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location2-elem-15 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-14 {
    padding: 40px 40px 40px 40px;
    flex-direction: column;
    border-width: 1px 1px 1px 1px;
    border-style: solid solid solid solid;
    border-color: #767D8666 #767D8666 #767D8666 #767D8666;
    height: auto;
    width: 100%;
    display: flex;
    margin-right: NaNpx;
    max-width: 290px;
    position: relative
}

.location2-elem-13 {
    padding: 0px 0px 0px 0px;
    color: #385173;
    text-align: left;
    font-size: 40px;
    line-height: 50px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10px;
    min-height: 10px;
    display: block;
    position: relative
}

.location2-elem-12 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #767D86;
    line-height: 26px;
    padding: 0px 0px 0px 0px;
    opacity: 100%;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: block;
    min-height: 10px;
    min-width: 16px;
    position: relative
}

.location2-elem-45 {
    padding: 15px 24px 15px 24px;
    background-color: #182433;
    border-radius: 0px 0px 0px 0px;
    border-width: 0px 0px 0px 0px;
    border-style: groove groove groove groove;
    border-color: #333 #333 #333 #333;
    font-family: Oxygen;
    font-size: 20px;
    font-weight: 600;
    color: #ffffffff;
    line-height: 30px;
    z-index: 1;
    margin-left: 636.3380281690141px;
    margin-top: 178px;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    max-width: 230px
}

.paragraph {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400
}